import styled from 'styled-components';
import { IconButton } from '../baseComponents';

import { StyledCopyToClipboard } from 'calcite-react/CopyToClipboard/CopyToClipboard-styled';

export const StyledIconButton = styled(IconButton)`
  flex-shrink: 0;
`;

export const StyledShareModalContainer = styled.div`
  margin-inline: -0.5rem;

  > button {
    margin-block-end: 1rem;
    margin-inline: 0.5rem;
    width: calc(50% - 1rem);
  }

  ${StyledCopyToClipboard} {
    margin-inline: 0.5rem;
  }
`;
