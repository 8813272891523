//Subcomponent
import VaccineDetail from './VaccineDetail';
import SyringeIcon from '../../assets/syringe-16.svg';
import {
  StyledVaccineSectionContainer,
  StyledVaccineDetailContainer,
  StyledVaccineName,
} from './LocationVaccineSection-styled';

const LocationVaccineSection = ({ facility, vaccineTypeFilter }) => {
  return (
    <>
      {vaccineTypeFilter.VaccineType !== 'Monkeypox' && (
        <StyledVaccineSectionContainer>
          <img src={SyringeIcon} alt="Syringe" />
          <StyledVaccineName>
            Vaccines offered at this location
          </StyledVaccineName>
        </StyledVaccineSectionContainer>
      )}
      <StyledVaccineDetailContainer>
        {facility.services.map((service) => {
          if (service.ServiceType.includes(vaccineTypeFilter.VaccineType)) {
            return (
              <VaccineDetail
                key={service.OBJECTID}
                service={service}
                phone={facility.Phone}
              />
            );
          }
        })}
      </StyledVaccineDetailContainer>
    </>
  );
};

export default LocationVaccineSection;
