// Framework and third-party non-ui
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
// Context & hooks
import { AppContext } from 'contexts/AppContext';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import {
  StyledFAQContainer,
  StyledHomeContainer,
  StyledHeroContainer,
  StyledHeroBrandingContainer,
  StyledCalciteH1,
  StyledHeroCalciteH2,
  StyledHeroLocatorContainer,
} from '../Home/Home-styled';

// App components
import FaqDropdown from 'components/FaqDropdown';

// App constants
import { isFluSeason } from 'constants/map';

// JSON

// CSS
import logo from '../../assets/logo.svg';
import { Route } from 'react-router-dom';

const Home = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { vaccineTypeFilter } = useContext(AppContext);

  return (
    <StyledHomeContainer>
      <Route
        path="/faq"
        component={() => {
          window.location.replace(
            'https://www1.nyc.gov/site/doh/covid/covid-19-vaccines.page'
          );
          return null;
        }}
      />
      <StyledHeroContainer>
        <StyledHeroBrandingContainer>
          <Link
            to="/"
            style={{ flexGrow: 0, lineHeight: 0 }}
            aria-label="Link to NYC Vaccinefinder homepage"
          >
            <img src={logo} alt="NYC Logo" />
          </Link>
          {isFluSeason ? (
            <StyledCalciteH1>COVID-19 and Flu Vaccine Finder</StyledCalciteH1>
          ) : (
            <StyledCalciteH1>COVID-19 Vaccine Finder</StyledCalciteH1>
          )}
        </StyledHeroBrandingContainer>
        <StyledHeroLocatorContainer>
          <StyledHeroCalciteH2 style={{ marginBottom: 0 }}>
            Frequently Asked Questions
          </StyledHeroCalciteH2>
        </StyledHeroLocatorContainer>
      </StyledHeroContainer>
      <StyledFAQContainer aria-label="Frequently Asked Questions">
        <FaqDropdown />
      </StyledFAQContainer>
    </StyledHomeContainer>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
