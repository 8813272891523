// Framework and third-party non-ui
import TextField from 'calcite-react/TextField';
import styled from 'styled-components';

export const StyledSearchContainer = styled.div`
  flex: 1;
  text-align: start;
  text-align: left;
  min-height: 32px;

  .esri-search {
    width: 100%;

    .esri-search__input {
      font-size: 16px;
    }
  }

  .esri-search__suggestions-menu {
    width: max-content;
    max-width: 24rem;
    min-width: 100%;
    z-index: 5;

    @media screen and (max-width: 650px) {
      max-width: 100%;
    }
  }
`;

export const StyledPlaceholderTextField = styled(TextField)`
  width: 100%;
  height: 32px;
  font-size: 0.9rem;
  padding-inline: 8px;
  pointer-events: none;
  border: none;

  ::placeholder {
    color: #ccc;
  }

  :-ms-input-placeholder {
    color: #ccc;
  }

  ::-ms-input-placeholder {
    color: #ccc;
  }
`;
