import React, { useEffect } from 'react';
import { Route, useRouteMatch, useHistory, Link } from 'react-router-dom';

import Home from 'pages/Home';
import Faq from 'pages/Faq';
import AppView from 'pages/AppView';
import MetaTags from './MetaTags';

import {
  StyledApp,
  StyledFooter,
  StyledImage,
  StyledLogoLink,
} from './App-styled';

import TranslationSelect from 'components/TranslationSelect';
//import ShareButton from 'components/ShareButton';
import FaqButton from 'components/FaqButton';

import AppContextProvider from 'contexts/AppContext';

import './App.css';
import logo from '../assets/logo.svg';

function App() {
  let isLocationRoute = useRouteMatch('/locations');

  //nyc webtrend usage
  let history = useHistory();
  useEffect(() => {
    var nycdns = 'vaccinefinder.nyc.gov';
    if (window.location.hostname.match(nycdns)) {
      history.listen((location) => {
        const pathName = location.pathname;
        const queryVals = location.search.replace('?', '');
        if (typeof dcsMultiTrack === 'function') {
          //console.log({ pathName, queryVals });
          window.dcsMultiTrack('DCS.dcsuri', pathName, 'DCS.dcsqry', queryVals);
        }
      });
      return () => {
        console.log('cleanup');
      };
    }
  }, []);
  //nyc webtrend end

  return (
    <AppContextProvider>
      <MetaTags />
      <StyledApp className="App">
        <Route exact path="/" component={Home} />
        <Route exact path="/faq" component={Faq} />
        <footer>
          <StyledFooter isLocationRoute={isLocationRoute}>
            <TranslationSelect />
            <StyledLogoLink to="/" as={Link}>
              <StyledImage src={logo} alt="NYC Logo"></StyledImage>
            </StyledLogoLink>
            <Route exact path="/" component={FaqButton} />
          </StyledFooter>
        </footer>
        <AppView />
      </StyledApp>
    </AppContextProvider>
  );
}

export default App;
