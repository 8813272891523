import { CalciteTheme } from 'calcite-react/CalciteThemeProvider';
import { unitCalc } from 'calcite-react/utils/helpers';
// import colors from '@esri/calcite-colors/dist/colors.js';

const primaryColor = '#0a5796';
const buttonColor = '#1771b7';
const buttonDarkerColor = '#003f7b';
const buttonLighterColor = '#81C3de';
const secondaryColor = '#288369';
const blackColor = '#333333';
const whiteColor = '#ffffff';
const offWhiteColor = '#eaeaea';
const offBlackColor = '#666666';
const accent1Color = '#bf4b0c';
const accent2Color = '#fff200';
const accent3Color = '#fb7d3f'; // Only to be used for the list/map toggle button
const accent3ColorAccessible = '#bf4b0c';
const successColor = '#3AA360';
const successColorAccessible = '#2E844E';
const failColor = '#C84504';

const theme = {
  ...CalciteTheme,

  palette: {
    ...CalciteTheme.palette,
    red: '#bf4b0c',
    darkRed: '#e8692a',
    offWhite: '#EFEFEF',
    colors: {
      primary: primaryColor,
      button: buttonColor,
      buttonHover: buttonDarkerColor,
      buttonSelected: buttonLighterColor,
      secondary: secondaryColor,
      black: blackColor,
      offBlack: offBlackColor,
      white: whiteColor,
      offWhite: offWhiteColor,
      accent1: accent1Color,
      accent2: accent2Color,
      accent3: accent3Color,
      accent3Accessible: accent3ColorAccessible,
      success: successColor,
      successAccessible: successColorAccessible,
      fail: failColor,
    },
  },
  global: {
    borderRadius: '20px',
    boxShadow: '0 0 0 1px rgba(0,0,0,0.1), rgba(0,0,0,0.15) 0px 0px 16px 0px',
    padding: unitCalc(CalciteTheme.baseline, 2, '/'),
    paddingSmall: unitCalc(CalciteTheme.baseline, 3, '/'),
    margin: unitCalc(CalciteTheme.baseline, 2, '/'),
    sidebarWidth: '24rem',
  },
  borderRadius: '20px',
};

export default theme;
