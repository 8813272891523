// Framework and third-party non-ui
import React, { useContext, useMemo } from 'react';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledListContainer,
  StyledCalciteP,
  MessageContainer,
} from './ListView-styled';

// App components
import LocationCard from 'components/LocationCard';
import MapListToggle from 'components/MapListToggle';
import A11yLegend from 'components/A11yLegend';

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const ListView = () => {
  const { facilities, vaccineTypeFilter } = useContext(AppContext);

  const facilitiesListcontent = useMemo(() => {
    if (facilities === null) {
      return [
        'loading-card-1',
        'loading-card-2',
        'loading-card-3',
        'loading-card-4',
        'loading-card-5',
        'loading-card-6',
        'loading-card-7',
        'loading-card-8',
        'loading-card-9',
        'loading-card-10',
      ].map((facility, i) => (
        <LocationCard
          key={facility}
          facility={null}
          selectable={false}
          showDirections={false}
        />
      ));
    }
    if (facilities?.length) {
      return (
        <>
          {facilities.map((facility, i) => (
            <LocationCard
              key={facility.attributes.OBJECTID}
              facility={facility}
            />
          ))}
        </>
      );
    } else {
      return (
        <MessageContainer>
          <StyledCalciteP>
            There are no sites that meet your search criteria.
            <br />
            Adjust the filter to see additional sites.
          </StyledCalciteP>
        </MessageContainer>
      );
    }
  });

  return (
    <StyledListContainer aria-label="Locations list">
      {(vaccineTypeFilter.VaccineType === 'COVID' ||
        vaccineTypeFilter.VaccineType === 'Monkeypox') && <A11yLegend />}
      {facilitiesListcontent}
      <MapListToggle />
    </StyledListContainer>
  );
};

ListView.propTypes = {};

ListView.defaultProps = {};

export default ListView;
