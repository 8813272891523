// Framework and third-party non-ui
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import { StyledLocationCardContainer } from './LocationCard-styled';

// App components
import LocationHeader from '../LocationHeader';
import { closePopUp } from 'utils/arcgis';
//import LocationDetailSection from '../LocationDetailSection';
import LocationButtons from './LocationButtons';
import { showMonkAvailCount } from 'constants/map';

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const LocationCard = ({
  facility,
  selectable = true,
  showDirections = true,
}) => {
  const {
    updateSelectedFacility,
    vaccineTypeFilter,
    focalPoint,
    mapView,
  } = useContext(AppContext);
  const attributes = facility?.attributes || [];
  const facilityName = attributes?.FacilityName || '';
  const facilityAddress = attributes?.Address || '';
  const ariaLabel = '' + facilityName + ' at ' + facilityAddress;

  //There can only be one Monkeypox service per facility so we can just use that one phone number and website if we are on monkeypox
  if (
    facility?.attributes !== undefined &&
    vaccineTypeFilter?.VaccineType === 'Monkeypox'
  ) {
    const monkAttributes = facility?.services?.find(
      (service) => service?.ServiceType === 'Monkeypox'
    );
    if (monkAttributes) {
      facility.attributes.Website = monkAttributes.Website;
      facility.attributes.Phone = monkAttributes.Phone;
      facility.attributes.Intake_WalkIn = monkAttributes.Intake_WalkIn;
      facility.attributes.AdditionalInfo = monkAttributes.AdditionalInfo;
    }
  }
  //Since covid and flu are now also using the url on the service layer, we need also search for those.
  else if (
    facility?.attributes !== undefined &&
    vaccineTypeFilter?.VaccineType === 'COVID'
  ) {
    const covidAttributes = facility?.services?.find((service) =>
      service?.ServiceType.includes('COVID')
    );
    if (covidAttributes) {
      facility.attributes.Website = covidAttributes.Website;
      facility.attributes.Phone = covidAttributes.Phone;
      facility.attributes.Intake_WalkIn = covidAttributes.Intake_WalkIn;
      facility.attributes.AdditionalInfo = covidAttributes.AdditionalInfo;
    }
  } else if (
    facility?.attributes !== undefined &&
    vaccineTypeFilter?.VaccineType === 'Flu'
  ) {
    const fluAttributes = facility?.services?.find((service) =>
      service?.ServiceType.includes('Flu')
    );
    if (fluAttributes) {
      facility.attributes.Website = fluAttributes.Website;
      facility.attributes.Phone = fluAttributes.Phone;
      facility.attributes.Intake_WalkIn = fluAttributes.Intake_WalkIn;
      facility.attributes.AdditionalInfo = fluAttributes.AdditionalInfo;
    }
  }

  return (
    <StyledLocationCardContainer
      selectable={selectable}
      onClick={() =>
        selectable && handleCardClick(facility, mapView, updateSelectedFacility)
      }
      aria-label={ariaLabel}
    >
      <LocationHeader
        facility={facility}
        showHours
        selectable={selectable}
        onFacilitySelected={() =>
          selectable &&
          handleCardClick(facility, mapView, updateSelectedFacility)
        }
        vaccineTypeFilter={vaccineTypeFilter}
      />
      <LocationButtons
        facility={facility}
        focalPoint={focalPoint}
        showDirections={showDirections}
        vaccineTypeFilter={vaccineTypeFilter}
      ></LocationButtons>
      {/* <LocationDetailSection
        facility={facility}
        fields={['Intake', 'Payment_Cash', 'Payment_Medicare']}
      /> */}
    </StyledLocationCardContainer>
  );
};

const handleCardClick = (facility, mapView, updateSelectedFacility) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  closePopUp(mapView);
  updateSelectedFacility(facility);
};

LocationCard.propTypes = {
  locationFeature: PropTypes.object.isRequired,
};

LocationCard.defaultProps = {
  locationFeature: {},
};

export default LocationCard;
