import styled from 'styled-components';
import { CalciteP } from 'calcite-react/Elements';

export const StyledMessage = styled.div`
  display: none;
  justify-content: space-between;
  width: 100%;
  background: ${(props) => props.theme.palette.colors.accent1};
  color: ${(props) => props.theme.palette.colors.white};
`;

export const StyledCalciteP = styled(CalciteP)`
  margin: 0.5rem ${(props) => props.theme.global.margin};
`;
