// Framework and third-party non-ui
import React, { useContext } from 'react';

// Redux operations and local helpers/utils/modules
import { Link } from 'react-router-dom';

// Component specific modules (Component-styled, etc.)
import {
  StyledHeader,
  StyledBrandingContainer,
  StyledCalciteH1,
  StyledLocatorContainer,
} from './Header-styled';

// App components
import Search from 'components/EsriSearch';

// Third-party components (buttons, icons, etc.)

// JSON

// CSS
import logo from '../../assets/whitelogo.svg';

// App Constants
import { isFluSeason, isMonkPoxSeason } from 'constants/map';

const Header = ({ ...props }) => {
  return (
    <StyledHeader {...props} role="banner" aria-label="Header">
      <StyledBrandingContainer>
        <Link
          to="/"
          style={{ lineHeight: 1, marginInlineEnd: '1.55rem' }}
          aria-label="Link to NYC Vaccinefinder homepage"
        >
          <img src={logo} alt="NYC Logo" />
        </Link>
        {isFluSeason && !isMonkPoxSeason && (
          <StyledCalciteH1>COVID-19 and Flu Vaccine Finder</StyledCalciteH1>
        )}
        {isMonkPoxSeason && <StyledCalciteH1>Vaccine Finder</StyledCalciteH1>}
        {!isFluSeason && !isMonkPoxSeason && (
          <StyledCalciteH1>COVID-19 Vaccine Finder</StyledCalciteH1>
        )}
      </StyledBrandingContainer>
      <StyledLocatorContainer>
        <Search />
      </StyledLocatorContainer>
    </StyledHeader>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
