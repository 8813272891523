import styled from 'styled-components';
import { CalciteLi } from 'calcite-react/Elements';

export const StyledCalciteLi = styled(CalciteLi)`
  margin: 0;
  margin-inline-start: 0;
  span {
    margin-inline: 0;
  }
`;
