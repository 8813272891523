// Framework and third-party non-ui
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Context
import { AppContext } from 'contexts/AppContext';
import { isEmail } from 'utils/helpers';
import { getDistanceFromFacility } from 'utils/arcgis';

// Component specific modules (Component-styled, etc.)
import { StyledListContainer } from './FacilityDetailsView-styled';

// App components
import LocationHeader from 'components/LocationHeader';
import MobileMapSection from 'components/MobileMapSection';
import LocationFooter from 'components/LocationFooter';
import LocationVaccineSection from 'components/LocationVaccineSection';
//import Breakpoint from 'App/Breakpoint';

// JSON

// CSS

const FacilityDetailsView = () => {
  const [proximity, setProximity] = useState(null);
  const { id } = useParams();
  const {
    selectedFacility,
    updateSelectedFacility,
    preloadedFacilityId,
    setPreloadedFacilityId,
    vaccineTypeFilter,
    focalPoint,
  } = useContext(AppContext);
  useEffect(() => {
    if (id && !preloadedFacilityId && !selectedFacility) {
      setPreloadedFacilityId(id);
    }
  }, [id, preloadedFacilityId, selectedFacility, setPreloadedFacilityId]);

  useEffect(() => {
    if (focalPoint && selectedFacility && !selectedFacility?.proximity) {
      selectedFacility.proximity = getDistanceFromFacility(
        focalPoint,
        selectedFacility
      );
      setProximity(selectedFacility.proximity);
    }
  }, [selectedFacility, focalPoint, updateSelectedFacility]);

  let facility = selectedFacility;
  //There can only be one Monkeypox service per facility so we can just use that one phone number and website if we are on monkeypox so changing the facility here is fine.
  if (
    facility?.attributes !== undefined &&
    vaccineTypeFilter?.VaccineType === 'Monkeypox'
  ) {
    const monkAttributes = facility?.services?.find(
      (service) => service?.ServiceType === 'Monkeypox'
    );
    if (monkAttributes) {
      facility.attributes.Website = monkAttributes.Website;
      facility.attributes.Phone = monkAttributes.Phone;
      facility.attributes.Intake_WalkIn = monkAttributes.Intake_WalkIn;
      facility.attributes.AdditionalInfo = monkAttributes.AdditionalInfo;
    }
  }
  //Since covid and flu are now also using the url on the service layer, we need also search for those.
  else if (
    facility?.attributes !== undefined &&
    vaccineTypeFilter?.VaccineType === 'COVID'
  ) {
    const covidAttributes = facility?.services?.find((service) =>
      service?.ServiceType?.includes('COVID')
    );
    if (covidAttributes) {
      facility.attributes.Website = covidAttributes.Website;
      facility.attributes.Phone = covidAttributes.Phone;
      facility.attributes.Intake_WalkIn = covidAttributes.Intake_WalkIn;
      facility.attributes.AdditionalInfo = covidAttributes.AdditionalInfo;
    }
  } else if (
    facility?.attributes !== undefined &&
    vaccineTypeFilter?.VaccineType === 'Flu'
  ) {
    const fluAttributes = facility?.services?.find((service) =>
      service?.ServiceType?.includes('Flu')
    );
    if (fluAttributes) {
      facility.attributes.Website = fluAttributes.Website;
      facility.attributes.Phone = fluAttributes.Phone;
      facility.attributes.Intake_WalkIn = fluAttributes.Intake_WalkIn;
      facility.attributes.AdditionalInfo = fluAttributes.AdditionalInfo;
    }
  }

  // Some websites are email addresses... prepend mailto: if thats the case
  let scheduleHref = facility?.attributes?.Website;

  if (scheduleHref && isEmail(scheduleHref)) {
    scheduleHref = `mailto:${scheduleHref}`;
  }

  return facility ? (
    <StyledListContainer>
      <LocationHeader
        facility={facility}
        onClose={() => updateSelectedFacility(null)}
        proximity={proximity}
        showVaccinesOffered={false}
        vaccineTypeFilter={vaccineTypeFilter}
      />
      <LocationVaccineSection
        facility={facility}
        vaccineTypeFilter={vaccineTypeFilter}
      />
      <MobileMapSection />
      <LocationFooter
        selectedFacility={facility}
        focalPoint={focalPoint}
        vaccineTypeFilter={vaccineTypeFilter}
      />
    </StyledListContainer>
  ) : null;
};

FacilityDetailsView.propTypes = {};

FacilityDetailsView.defaultProps = {};

export default FacilityDetailsView;
