// Framework and third-party non-ui
import React from 'react';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledFloatingButton } from './MapListToggle-styled';

// App components

// Third-party components (buttons, icons, etc.)
import ListBulletIcon from 'calcite-ui-icons-react/ListBulletIcon';
import ArrowLeftIcon from 'calcite-ui-icons-react/ArrowLeftIcon';
import MapSvg from '../../assets/map.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// JSON

// CSS

const MapListToggle = (props) => {
  const history = useHistory();
  let { pathname, search } = useLocation();
  if (pathname.includes('Monkeypox')) {
    pathname = pathname.replace('Monkeypox', 'Mpox');
  }
  const { id } = useParams();

  const mView = new URLSearchParams(search).get('mView');

  let label, icon;
  if (mView === 'map') {
    if (id) {
      // id seems to be set to undefined in one of the renders for some reason
      label = 'Back';
      icon = <ArrowLeftIcon size={16} />;
    } else {
      label = 'View list';
      icon = <ListBulletIcon size={16} />;
    }
  } else {
    label = 'View map';
    icon = <img src={MapSvg} alt="map" />;
  }

  const toggleView = () => {
    if (mView === 'map') {
      // Toggle off
      history.push({
        pathname: pathname,
        search: '',
      });
    } else {
      // Toggle on
      history.push({
        pathname: pathname,
        search: '?mView=map',
      });
    }
  };

  return (
    <StyledFloatingButton
      icon={icon}
      iconPosition="before"
      onClick={toggleView}
      {...props}
    >
      {label}
    </StyledFloatingButton>
  );
};

MapListToggle.propTypes = {};

MapListToggle.defaultProps = {};

export default MapListToggle;
