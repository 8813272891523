// Framework and third-party non-ui
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledFilterSubNavContainer,
  FilterButton,
  PopperActions,
  StyledCalciteP,
} from '../CovidFilterSubNav/CovidFilterSubNav-styled';

// App components
import InsuranceFilter from './InsuranceFilter';

// Third-party components (buttons, icons, etc.)
import { usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'calcite-react/Button';
import CaretDownIcon from 'calcite-ui-icons-react/CaretDownIcon';

import {
  StyledDisplayCheckbox,
  StyledCheckboxInput,
  StyledCheckboxGroup,
  StyledCheckboxCheckIcon,
} from 'calcite-react/Checkbox/Checkbox-styled';

// JSON
import strings from 'strings/en.json';

// CSS

const fluTypes = ['flu17', 'flu18'];

const FluFilterSubNav = ({ openFilter }) => {
  let showFilter = useRouteMatch({
    path: ['/locations/Flu/', '/'],
    exact: true,
  });

  const { attributeFilters, updateAttributeFilters } = useContext(AppContext);

  const [vaccineVisibility, setVaccineVisibility] = useState(false);
  const vaccineReferenceRef = useRef(null);
  const vaccinePopperRef = useRef(null);
  const firstVaccineRef = useRef(null);

  const { styles, attributes, update } = usePopper(
    vaccineReferenceRef.current,
    vaccinePopperRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  const [draftFluTypeUnder18Filters, setUnder18Filters] = useState(() => {
    if (
      attributeFilters.draftFluTypeUnder18Filters === 'No' ||
      attributeFilters.draftFluTypeUnder18Filters === null
    ) {
      return 'Yes';
    } else {
      return 'No';
    }
  });

  const [draftFluTypeOver18Filters, setOver18Filters] = useState(() => {
    if (
      attributeFilters.AgesServed_Over18 === 'No' ||
      attributeFilters.AgesServed_Over18 === null
    ) {
      return 'Yes';
    } else {
      return 'No';
    }
  });

  const applyFilter = () => {
    if (!!draftFluTypeUnder18Filters || !!draftFluTypeOver18Filters) {
      updateAttributeFilters({
        ...attributeFilters,
        AgesServed_Under18: draftFluTypeUnder18Filters,
        AgesServed_Over18: draftFluTypeOver18Filters,
      });
    } else {
      updateAttributeFilters({
        ...attributeFilters,
        AgesServed_Under18: draftFluTypeUnder18Filters,
        AgesServed_Over18: draftFluTypeOver18Filters,
      });
    }
    setVaccineVisibility(false);
  };

  const selectAllVaccines = () => {
    setUnder18Filters('Yes');
    setOver18Filters('Yes');
  };

  const applyCheck = (value) => {
    switch (value) {
      case 'flu17':
        return draftFluTypeUnder18Filters === 'Yes';
      case 'flu18':
        return draftFluTypeOver18Filters === 'Yes';
    }
  };

  const updateFluFilter = (value, checked) => {
    if (checked) {
      switch (value) {
        case 'flu17':
          setUnder18Filters('Yes');
          break;
        case 'flu18':
          setOver18Filters('Yes');
          break;
      }
    } else {
      switch (value) {
        case 'flu17':
          setUnder18Filters('');
          break;
        case 'flu18':
          setOver18Filters('');
          break;
      }
    }
  };

  const hideVaccinePopper = (event) => {
    if (vaccineVisibility && event.target.tagName !== 'INPUT') {
      // Refocus dropdown button if the popuup was open
      // Don't change focus if they're clicking on an input
      vaccineReferenceRef.current.focus();
    }

    // Popup was closed without being applied
    setVaccineVisibility(false);

    // Revert draft values back to applied values
    setUnder18Filters(attributeFilters.AgesServed_Under18 || '');
    setOver18Filters(attributeFilters.AgesServed_Over18 || '');
  };

  const handleKeyup = (e) => {
    if (e.key === 'Escape' && vaccineVisibility) {
      hideVaccinePopper();
    }
  };

  useEffect(() => {
    if (vaccineVisibility) {
      firstVaccineRef.current.focus();
    }

    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [vaccineVisibility, firstVaccineRef]);

  const showVaccinePopper = (event) => {
    update();
    setVaccineVisibility(true);
  };

  const containerStyle = {
    ...styles.popper,
    display: vaccineVisibility ? 'flex' : 'none',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
    padding: '10px',
  };

  const vaccineTypeAriaLabel = useMemo(() => {
    let count = 0;
    if (!!attributeFilters.AgesServed_Over18) count++;
    if (!!attributeFilters.AgesServed_Under18) count++;
    if (count === 0) {
      return '';
    } else if (count === fluTypes.length) {
      return ' Any Age.';
    }

    return ` ${count} Vaccine type${count > 1 ? 's' : ''} selected.`;
  }, [attributeFilters.AgesServed_Over18, attributeFilters.AgesServed_Under18]);

  const getLabel = () => {
    if (
      attributeFilters.AgesServed_Over18 === attributeFilters.AgesServed_Under18
    ) {
      return `Any age`;
    } else {
      return `Age (1)`;
    }
  };

  return (
    <StyledFilterSubNavContainer
      role="region"
      aria-label="Filter locations"
      showFilter={showFilter}
    >
      {
        <div
          role="dialog"
          ref={vaccinePopperRef}
          style={containerStyle}
          {...attributes.popper}
        >
          <OutsideClickHandler onOutsideClick={hideVaccinePopper}>
            {fluTypes.map((fluType, i) => (
              <StyledCheckboxGroup key={fluType}>
                <StyledCheckboxInput
                  ref={i === 0 ? firstVaccineRef : null}
                  checked={applyCheck(fluType)}
                  onChange={(e) => {
                    updateFluFilter(fluType, e.target.checked);
                  }}
                  type="checkbox"
                  value={fluType}
                  name={fluType}
                />
                <StyledDisplayCheckbox>
                  <StyledCheckboxCheckIcon>
                    <path
                      d="M12.753 3l-7.319 7.497L3.252 8.31 2 9.373l3.434 3.434L14 4.24z"
                      fill="white"
                    />
                  </StyledCheckboxCheckIcon>
                </StyledDisplayCheckbox>
                <StyledCalciteP>{strings[fluType]}</StyledCalciteP>
              </StyledCheckboxGroup>
            ))}
            <PopperActions>
              <Button inline onClick={() => selectAllVaccines()}>
                Select all
              </Button>
              <Button
                onClick={() => {
                  applyFilter();
                  // Refocus dropdown button
                  vaccineReferenceRef.current.focus();
                }}
              >
                Done
              </Button>
            </PopperActions>
          </OutsideClickHandler>
        </div>
      }
      <InsuranceFilter />
    </StyledFilterSubNavContainer>
  );
};

FluFilterSubNav.propTypes = {
  openFilter: PropTypes.func,
};

FluFilterSubNav.defaultProps = {};

export default FluFilterSubNav;
