import styled from 'styled-components';

import { StyledFloatingButton } from 'components/MapListToggle/MapListToggle-styled';

import MobileMapBg from 'assets/mobile-map-bg.jpg';
import { CalciteH5 } from 'calcite-react/Elements';

export const StyledMobileMapSectionContainer = styled.div`
  display: none;
  padding: 0.25rem;
  margin: 0.5rem 0;
  padding: 0;
  background: ${(props) => props.theme.palette.white};
  border-radius: 6px;
  overflow: hidden;

  h5 {
    color: ${(props) => props.theme.palette.colors.primary};
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0;
  }

  @media screen and (max-width: 650px) {
    display: block;
  }
`;

export const StyledCalciteH5 = styled(CalciteH5)`
  font-size: 0.9rem !important;
  font-weight: bold;
`;

export const StyledMobileMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 260px;
  overflow: hidden;
  background: #a7d6fe;
  background-image: url(${MobileMapBg});
  background-position: center;
  background-size: cover;
`;

export const StyledMapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);

  ${StyledFloatingButton} {
    background: ${(props) => props.theme.palette.colors.button};
    border: ${(props) => props.theme.palette.colors.button};
  }
`;
