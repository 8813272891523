// Framework and third-party non-ui
import React from 'react';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledPanelHeader, StyledCalciteH2 } from './PanelHeader-styled';

// App components

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';
import XIcon from 'calcite-ui-icons-react/XIcon';

// JSON

// CSS

const PanelHeader = ({ children, onClose, ...props }) => {
  return (
    <StyledPanelHeader {...props}>
      <StyledCalciteH2>{children}</StyledCalciteH2>
      {onClose && (
        <Button
          iconButton
          icon={<XIcon />}
          onClick={onClose}
          aria-label="close panel"
        />
      )}
    </StyledPanelHeader>
  );
};

PanelHeader.propTypes = {};

PanelHeader.defaultProps = {};

export default PanelHeader;
