// Framework and third-party non-ui
import React, { useContext, useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  SearchWrapper,
  StyledHomeContainer,
  StyledHeroContainer,
  StyledHeroBrandingContainer,
  StyledCalciteH1,
  StyledHeroCalciteH2,
  StyledListContainer,
  StyledCalciteP,
  StyledFloatingLocateButton,
  StyledHeroLocatorContainer,
  StyledCalciteH2,
} from './Home-styled';
import Loader from 'calcite-react/Loader';
import GpsOnFIcon from 'calcite-ui-icons-react/GpsOnFIcon';

// App components
import Search from 'components/EsriSearch';
import LocationCard from 'components/LocationCard';
import A11yLegend from 'components/A11yLegend';
import CovidFilterSubNav from 'components/CovidFilterSubNav';
import FluFilterSubNav from 'components/FluFilterSubNav';
import MonkFilterSubNav from 'components/MonkFilterSubNav';
import VaccineTypeToggle from 'components/VaccineTypeToggle/VaccineTypeToggle';
import { StyledHomeAlert, StyledHomeAlertMessage } from './Home-styled';

//App Constants
import {
  isFluSeason,
  isMonkPoxSeason,
  showMonkAvailCount,
} from 'constants/map';

// Third-party components (buttons, icons, etc.)

// JSON
import strings from 'strings/en.json';

// CSS
import logo from '../../assets/logo.svg';

const Home = (props) => {
  const {
    mapView,
    locateWidget,
    updateSortedFacilities,
    attributeFilters,
    sortedFacilities,
    vaccineTypeFilter,
    showLoading,
    bannerTexts,
    facilitiesAvail,
  } = useContext(AppContext);

  const { firstLoad, setFirstLoad } = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateSortedFacilities();
  }, [vaccineTypeFilter.VaccineType, mapView, attributeFilters]);

  useEffect(() => {
    if (!showLoading && setFirstLoad === 0) setFirstLoad(1);
    if (!showLoading && setFirstLoad === 1) setFirstLoad(2);
  }, [showLoading, setFirstLoad, firstLoad]);

  const facilitiesListcontent = useMemo(() => {
    if (sortedFacilities === null) {
      return [
        'loading-card-1',
        'loading-card-2',
        'loading-card-3',
      ].map((facility, i) => (
        <LocationCard
          key={i}
          facility={null}
          selectable={false}
          showDirections={false}
        />
      ));
    }
    if (sortedFacilities?.length) {
      return (
        <>
          {vaccineTypeFilter.VaccineType === 'COVID' ||
          vaccineTypeFilter.VaccineType === 'Monkeypox' ? (
            <A11yLegend />
          ) : null}
          {sortedFacilities
            //.sort((a, b) => (a.FacilityName > b.FacilityName ? 1 : -1))
            .map((facility, i) => (
              <LocationCard
                key={facility.attributes.OBJECTID}
                facility={facility}
                selectable={false}
                showDirections={false}
              />
            ))}
        </>
      );
    } else {
      return (
        <StyledCalciteP>
          Keep checking, availability information is updated frequently
        </StyledCalciteP>
      );
    }
  }, [sortedFacilities, vaccineTypeFilter.VaccineType]);

  const onClickLocateButton = () => {
    locateWidget.locate();
  };

  return (
    <StyledHomeContainer>
      <StyledHeroContainer>
        <StyledHeroBrandingContainer>
          <img src={logo} alt="NYC Logo" />
          {isFluSeason && !isMonkPoxSeason && (
            <StyledCalciteH1>COVID-19 and Flu Vaccine Finder</StyledCalciteH1>
          )}
          {isMonkPoxSeason && <StyledCalciteH1>Vaccine Finder</StyledCalciteH1>}
          {!isFluSeason && !isMonkPoxSeason && (
            <StyledCalciteH1>COVID-19 Vaccine Finder</StyledCalciteH1>
          )}
        </StyledHeroBrandingContainer>
        {/* Showing/hiding of the StyledHomeAlert/StyledHomeAlertMessage is
        controlled by whether or not bannerTexts is empty. If not empty,
        then it is shown and vice versa.
        */}
        {bannerTexts?.length > 0 && (
          <StyledHomeAlert full>
            <StyledHomeAlertMessage>
              {bannerTexts.map((e) => {
                return e + '\n';
              })}
            </StyledHomeAlertMessage>
          </StyledHomeAlert>
        )}
        <StyledHeroLocatorContainer
          role="region"
          aria-labelledby="findLocationLabel"
        >
          <StyledHeroCalciteH2 id="findLocationLabel">
            Find a Location
          </StyledHeroCalciteH2>
          <SearchWrapper>
            <Search />
            <Link
              to={
                '/locations/' +
                (vaccineTypeFilter.VaccineType === 'Monkeypox'
                  ? 'Mpox'
                  : vaccineTypeFilter.VaccineType) +
                '/'
              }
              style={{ flexGrow: 0 }}
              title="Use Current Location"
              aria-label="Use Current Location"
            >
              <StyledFloatingLocateButton
                onClick={() => onClickLocateButton()}
                fullWidth
                icon={<GpsOnFIcon size={20} />}
                iconPosition="before"
                aria-label="Use Current Location"
              ></StyledFloatingLocateButton>
            </Link>
          </SearchWrapper>
        </StyledHeroLocatorContainer>
        {(isFluSeason || isMonkPoxSeason) && <VaccineTypeToggle />}
        {vaccineTypeFilter.VaccineType === 'Monkeypox' && <MonkFilterSubNav />}
        {vaccineTypeFilter.VaccineType === 'Flu' && <FluFilterSubNav />}
        {vaccineTypeFilter.VaccineType === 'COVID' && <CovidFilterSubNav />}
      </StyledHeroContainer>

      {/* Label for available monkeypox sites count, to toggle, change showMonkAvailCount in constants/map.js */}
      <StyledListContainer aria-labelledby="availableSitesLabel">
        {/* {vaccineTypeFilter.VaccineType === 'Monkeypox' && showMonkAvailCount && (
          <StyledCalciteH2 id="availableSitesLabel">
            Sites {'with available appointments'}{' '}
            <span className="notranslate" content="notranslate">
              ({facilitiesAvail?.length || 0})
            </span>
          </StyledCalciteH2>
        )} */}
        {facilitiesListcontent}
      </StyledListContainer>

      <div>
        {showLoading === 0 && (
          <div
            role="alert"
            aria-label="Map is loading. This may take a few minutes"
            text="Map is loading. This may take a few minutes"
          />
        )}
        {showLoading === 1 && (
          <div
            role="alert"
            aria-label="Map is done loading."
            text="Map is done loading."
          />
        )}
        {showLoading === 2 && (
          <div
            role="alert"
            aria-label="Loading Results"
            text="Loading Results"
          />
        )}
        {showLoading === 3 && (
          <div role="alert" aria-label="Results Loaded" text="Results Loaded" />
        )}
      </div>
    </StyledHomeContainer>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
