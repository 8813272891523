import styled, { css } from 'styled-components';
import { unitCalc } from 'calcite-react/utils/helpers';
import { CalciteA } from 'calcite-react/Elements/Elements-styled';
import Footer from 'components/Footer';

export const StyledApp = styled.div`
  ${CalciteA} {
    color: ${(props) => props.theme.palette.colors.primary};
    text-decoration: underline;
  }
`;

export const StyledFooter = styled(Footer)`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: white;
  z-index: 5;

  @media screen and (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
    top: 0;
    bottom: auto;
    box-shadow: none;
  }

  ${(props) =>
    props.isLocationRoute &&
    css`
      display: none;
    `};
`;

export const StyledLogoLink = styled.a`
  display: none;
  @media screen and (min-width: 650px) {
    display: block;
    justify-self: center;
  }
`;

export const StyledImage = styled.img`
  height: ${(props) => unitCalc(props.theme.baseline, 2, '*')};
  display: none;
  @media screen and (min-width: 650px) {
    display: block;
    justify-self: center;
  }
`;
