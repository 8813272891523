// Framework and third-party non-ui
import React from 'react';
import PropTypes from 'prop-types';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import {
  StyledA11yLegendContainer,
  StyledFieldValuesContainer,
} from './A11yLegend-styled';

// App components

// Third-party components (buttons, icons, etc.)
import { CalciteLi } from 'calcite-react/Elements';
import AdaIconPng from 'assets/wheelchair-move-medium-blue.png';

// JSON

// CSS

const A11yLegend = () => {
  return (
    <StyledA11yLegendContainer>
      <StyledFieldValuesContainer>
        <CalciteLi>
          <img
            src={AdaIconPng}
            alt="Wheelchair access icon"
            title="This icon indicates a site has a physically accessible pathway to
            the vaccine administration."
            style={{ width: 12, height: 14 }}
          />
          <span>
            This icon indicates a site has a physically accessible pathway to
            the vaccine administration.
          </span>
        </CalciteLi>
      </StyledFieldValuesContainer>
    </StyledA11yLegendContainer>
  );
};

A11yLegend.propTypes = {};

A11yLegend.defaultProps = {};

export default A11yLegend;
