const webmapId = '3162588dbaba484fa1c45e300b34f121'; // nycgov
// const webmapId = '432a97b4b53e4b36a2d5420059465188'; // dbsne
// const webmapId = '67c88d61537143d890b0092b32ed819b'; // TEST web map for 13129

const fields = {
  FACILITY_NAME: 'name',
};

const environments = {
  localhost: 'dev',
  'dev.nycvaccinefinder.aws.esri-ps.com': 'dev',
  'staging.nycvaccinefinder.aws.esri-ps.com': 'staging',
  'demo.nycvaccinefinder.aws.esri-ps.com': 'demo',
  'vaccinefinder.nyc.gov': 'prod',
};

//* Webmaps used by this app
//* Vaccine Finder App – DEV (462e859452bd4d818a8ad3314e15c840)
//* Vaccine Finder App – STG (99366145b36d401f8ee42bd1f5e8d5e6)
//* Vaccine Finder App – PROD (3162588dbaba484fa1c45e300b34f121)

const webmaps = {
  dev: '462e859452bd4d818a8ad3314e15c840',
  staging: '99366145b36d401f8ee42bd1f5e8d5e6',
  demo: '3162588dbaba484fa1c45e300b34f121',
  prod: '3162588dbaba484fa1c45e300b34f121',
};

//* Feature Server Tables used by this app to get Notice banner data
//* Vaccine Finder App – DEV (07b0dca41f3a48de96a50ce675431082)
//* Vaccine Finder App – STG (df778bba614d4977af918f6992909b84)
//* Vaccine Finder App – PROD (b99a3e34feda49a799553240964a1998)

const noticeServerTables = {
  dev:
    'https://services6.arcgis.com/yG5s3afENB5iO9fj/arcgis/rest/services/DEV_VaccineFinderNotices_VIEW/FeatureServer/0',
  staging:
    'https://services6.arcgis.com/yG5s3afENB5iO9fj/arcgis/rest/services/DataQA_VaccineFinderNotices_VIEW/FeatureServer/0',
  demo:
    'https://services6.arcgis.com/yG5s3afENB5iO9fj/arcgis/rest/services/PROD_VaccineFinderNotices_VIEW/FeatureServer/0',
  prod:
    'https://services6.arcgis.com/yG5s3afENB5iO9fj/arcgis/rest/services/PROD_VaccineFinderNotices_VIEW/FeatureServer/0',
};

// built with https://developers.arcgis.com/javascript/latest/guide/cim-builder/
// red rounded pushpin symbol with NO outline
const pushpinSymbol = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.5,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 24,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [17.17, 14.33],
                    [16.97, 12.96],
                    [16.38, 11.37],
                    [12.16, 3.98],
                    [11.2, 1.94],
                    [10.5, 0],
                    [9.8, 1.96],
                    [8.84, 4.02],
                    [4.61, 11.41],
                    [4.02, 12.98],
                    [3.83, 14.33],
                    [3.96, 15.63],
                    [4.34, 16.88],
                    [4.95, 18.03],
                    [5.78, 19.04],
                    [6.8, 19.88],
                    [7.95, 20.49],
                    [9.2, 20.87],
                    [10.5, 21],
                    [11.8, 20.87],
                    [13.05, 20.5],
                    [14.2, 19.88],
                    [15.22, 19.05],
                    [16.05, 18.03],
                    [16.66, 16.88],
                    [17.04, 15.63],
                    [17.17, 14.33],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0,
                    color: [110, 110, 110, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [221, 54, 58, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
      ],
    },
  },
};

const orangeRoundedPinSymbol = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.5,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 24,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [17.17, 14.33],
                    [16.97, 12.96],
                    [16.38, 11.37],
                    [12.16, 3.98],
                    [11.2, 1.94],
                    [10.5, 0],
                    [9.8, 1.96],
                    [8.84, 4.02],
                    [4.61, 11.41],
                    [4.02, 12.98],
                    [3.83, 14.33],
                    [3.96, 15.63],
                    [4.34, 16.88],
                    [4.95, 18.03],
                    [5.78, 19.04],
                    [6.8, 19.88],
                    [7.95, 20.49],
                    [9.2, 20.87],
                    [10.5, 21],
                    [11.8, 20.87],
                    [13.05, 20.5],
                    [14.2, 19.88],
                    [15.22, 19.05],
                    [16.05, 18.03],
                    [16.66, 16.88],
                    [17.04, 15.63],
                    [17.17, 14.33],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0,
                    color: [251, 125, 63, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [251, 125, 63, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
      ],
    },
  },
};

const redRoundedPinSymbol = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.5,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 24,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [17.17, 14.33],
                    [16.97, 12.96],
                    [16.38, 11.37],
                    [12.16, 3.98],
                    [11.2, 1.94],
                    [10.5, 0],
                    [9.8, 1.96],
                    [8.84, 4.02],
                    [4.61, 11.41],
                    [4.02, 12.98],
                    [3.83, 14.33],
                    [3.96, 15.63],
                    [4.34, 16.88],
                    [4.95, 18.03],
                    [5.78, 19.04],
                    [6.8, 19.88],
                    [7.95, 20.49],
                    [9.2, 20.87],
                    [10.5, 21],
                    [11.8, 20.87],
                    [13.05, 20.5],
                    [14.2, 19.88],
                    [15.22, 19.05],
                    [16.05, 18.03],
                    [16.66, 16.88],
                    [17.04, 15.63],
                    [17.17, 14.33],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 1,
                    color: [255, 255, 255, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [227, 26, 28, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
      ],
    },
  },
};

const redRoundedPinWithCircleSymbol = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: 0,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 7,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 17,
            ymax: 17,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [8.5, 0],
                    [7.02, 0.13],
                    [5.59, 0.51],
                    [4.25, 1.14],
                    [3.04, 1.99],
                    [1.99, 3.04],
                    [1.14, 4.25],
                    [0.51, 5.59],
                    [0.13, 7.02],
                    [0, 8.5],
                    [0.13, 9.98],
                    [0.51, 11.41],
                    [1.14, 12.75],
                    [1.99, 13.96],
                    [3.04, 15.01],
                    [4.25, 15.86],
                    [5.59, 16.49],
                    [7.02, 16.87],
                    [8.5, 17],
                    [9.98, 16.87],
                    [11.41, 16.49],
                    [12.75, 15.86],
                    [13.96, 15.01],
                    [15.01, 13.96],
                    [15.86, 12.75],
                    [16.49, 11.41],
                    [16.87, 9.98],
                    [17, 8.5],
                    [16.87, 7.02],
                    [16.49, 5.59],
                    [15.86, 4.25],
                    [15.01, 3.04],
                    [13.96, 1.99],
                    [12.75, 1.14],
                    [11.41, 0.51],
                    [9.98, 0.13],
                    [8.5, 0],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0,
                    color: [0, 0, 0, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [178, 24, 43, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
          offsetY: 16,
        },
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.5,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 24,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [17.17, 14.33],
                    [16.97, 12.96],
                    [16.38, 11.37],
                    [12.16, 3.98],
                    [11.2, 1.94],
                    [10.5, 0],
                    [9.8, 1.96],
                    [8.84, 4.02],
                    [4.61, 11.41],
                    [4.02, 12.98],
                    [3.83, 14.33],
                    [3.96, 15.63],
                    [4.34, 16.88],
                    [4.95, 18.03],
                    [5.78, 19.04],
                    [6.8, 19.88],
                    [7.95, 20.49],
                    [9.2, 20.87],
                    [10.5, 21],
                    [11.8, 20.87],
                    [13.05, 20.5],
                    [14.2, 19.88],
                    [15.22, 19.05],
                    [16.05, 18.03],
                    [16.66, 16.88],
                    [17.04, 15.63],
                    [17.17, 14.33],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0.75,
                    color: [255, 255, 255, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [227, 26, 28, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
          colorLocked: false,
          rotation: 0,
        },
      ],
    },
  },
};

const redEsriPinWithCircleSymbol = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: 0,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 7,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 17,
            ymax: 17,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [8.5, 0],
                    [7.02, 0.13],
                    [5.59, 0.51],
                    [4.25, 1.14],
                    [3.04, 1.99],
                    [1.99, 3.04],
                    [1.14, 4.25],
                    [0.51, 5.59],
                    [0.13, 7.02],
                    [0, 8.5],
                    [0.13, 9.98],
                    [0.51, 11.41],
                    [1.14, 12.75],
                    [1.99, 13.96],
                    [3.04, 15.01],
                    [4.25, 15.86],
                    [5.59, 16.49],
                    [7.02, 16.87],
                    [8.5, 17],
                    [9.98, 16.87],
                    [11.41, 16.49],
                    [12.75, 15.86],
                    [13.96, 15.01],
                    [15.01, 13.96],
                    [15.86, 12.75],
                    [16.49, 11.41],
                    [16.87, 9.98],
                    [17, 8.5],
                    [16.87, 7.02],
                    [16.49, 5.59],
                    [15.86, 4.25],
                    [15.01, 3.04],
                    [13.96, 1.99],
                    [12.75, 1.14],
                    [11.41, 0.51],
                    [9.98, 0.13],
                    [8.5, 0],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0,
                    color: [0, 0, 0, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [178, 24, 43, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
          offsetY: 16,
        },
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.5,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 24,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [16.06, 5.58],
                    [10.5, 0],
                    [4.94, 5.58],
                    [4.94, 16.73],
                    [5.03, 17.69],
                    [5.3, 18.54],
                    [5.75, 19.27],
                    [6.37, 19.88],
                    [7.17, 20.37],
                    [8.14, 20.72],
                    [9.25, 20.93],
                    [10.5, 21],
                    [11.75, 20.93],
                    [12.86, 20.72],
                    [13.83, 20.37],
                    [14.63, 19.88],
                    [15.25, 19.27],
                    [15.7, 18.54],
                    [15.97, 17.69],
                    [16.06, 16.73],
                    [16.06, 5.58],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0.75,
                    color: [255, 255, 255, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [227, 26, 28, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
      ],
    },
  },
};

// circle with green fill, white outline
const searchResultSymbol = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: 0,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 12,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 17,
            ymax: 17,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [8.5, 0],
                    [7.02, 0.13],
                    [5.59, 0.51],
                    [4.25, 1.14],
                    [3.04, 1.99],
                    [1.99, 3.04],
                    [1.14, 4.25],
                    [0.51, 5.59],
                    [0.13, 7.02],
                    [0, 8.5],
                    [0.13, 9.98],
                    [0.51, 11.41],
                    [1.14, 12.75],
                    [1.99, 13.96],
                    [3.04, 15.01],
                    [4.25, 15.86],
                    [5.59, 16.49],
                    [7.02, 16.87],
                    [8.5, 17],
                    [9.98, 16.87],
                    [11.41, 16.49],
                    [12.75, 15.86],
                    [13.96, 15.01],
                    [15.01, 13.96],
                    [15.86, 12.75],
                    [16.49, 11.41],
                    [16.87, 9.98],
                    [17, 8.5],
                    [16.87, 7.02],
                    [16.49, 5.59],
                    [15.86, 4.25],
                    [15.01, 3.04],
                    [13.96, 1.99],
                    [12.75, 1.14],
                    [11.41, 0.51],
                    [9.98, 0.13],
                    [8.5, 0],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 1.5,
                    color: [255, 255, 255, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [51, 160, 44, 255],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
          colorLocked: false,
        },
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: 0,
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 15.5,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 17,
            ymax: 17,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [8.5, 0],
                    [7.02, 0.13],
                    [5.59, 0.51],
                    [4.25, 1.14],
                    [3.04, 1.99],
                    [1.99, 3.04],
                    [1.14, 4.25],
                    [0.51, 5.59],
                    [0.13, 7.02],
                    [0, 8.5],
                    [0.13, 9.98],
                    [0.51, 11.41],
                    [1.14, 12.75],
                    [1.99, 13.96],
                    [3.04, 15.01],
                    [4.25, 15.86],
                    [5.59, 16.49],
                    [7.02, 16.87],
                    [8.5, 17],
                    [9.98, 16.87],
                    [11.41, 16.49],
                    [12.75, 15.86],
                    [13.96, 15.01],
                    [15.01, 13.96],
                    [15.86, 12.75],
                    [16.49, 11.41],
                    [16.87, 9.98],
                    [17, 8.5],
                    [16.87, 7.02],
                    [16.49, 5.59],
                    [15.86, 4.25],
                    [15.01, 3.04],
                    [13.96, 1.99],
                    [12.75, 1.14],
                    [11.41, 0.51],
                    [9.98, 0.13],
                    [8.5, 0],
                  ],
                ],
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0,
                    color: [0, 0, 0, 255],
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [51, 51, 51, 33],
                  },
                ],
              },
            },
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true,
        },
      ],
    },
  },
};

const isFluSeason = true;

const isMonkPoxSeason = true;

const showMonkAvailCount = false;

export {
  webmapId,
  noticeServerTables,
  fields,
  environments,
  webmaps,
  pushpinSymbol,
  orangeRoundedPinSymbol,
  redRoundedPinSymbol,
  redRoundedPinWithCircleSymbol,
  redEsriPinWithCircleSymbol,
  searchResultSymbol,
  isFluSeason,
  isMonkPoxSeason,
  showMonkAvailCount,
};
