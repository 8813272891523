import styled, { css } from 'styled-components';

export const StyledLocationCardContainer = styled.article`
  padding: ${(props) => props.theme.global.padding};
  background: ${(props) => props.theme.palette.white};
  box-shadow: 0 0 0 transparent;

  ${(props) =>
    props.onClick &&
    props.selectable &&
    css`
      &:hover {
        cursor: pointer;
        box-shadow: ${props.theme.boxShadow};
        transition: box-shadow 0.2s ease-in-out;
      }
    `};
`;

export const LocationButtonsContainer = styled.article`
  display: inline-flex;
  margin-top: 5px;

  > button,
  > a {
    margin-inline-end: 0.3rem;
    padding: 0.3rem 0.75rem;
    height: 28px;
  }
`;
