import * as watchUtils from '@arcgis/core/core/watchUtils';
import Locate from '@arcgis/core/widgets/Locate';
import Point from '@arcgis/core/geometry/Point';
import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import CustomContent from '@arcgis/core/popup/content/CustomContent';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polyline from '@arcgis/core/geometry/Polyline';
import Query from '@arcgis/core/tasks/support/Query';
import QueryTask from '@arcgis/core/tasks/QueryTask';
import React from 'react';
import ReactDOM from 'react-dom';
import LocationHeader from 'components/LocationHeader';
import { getNoticeServerTableUrl } from './helpers';

import { orangeRoundedPinSymbol } from 'constants/map';
import LocationFooter from 'components/LocationFooter';

const FACILITIES_LAYER_TITLE = 'Facilities';
const SERVICES_LAYER_TITLE = 'Services';
const SELECTED_FACILITY_LAYER_ID = 'selected-facility-graphics-layer';

const initMapView = async function ({
  view,
  setMapLoaded,
  vaccineTypeFilter,
  updateSelectedFacility,
  setPopupDirectionsRequest,
  focalPoint,
}) {
  // Load the webmap and wait for it to be loaded
  view.popup.defaultPopupTemplateEnabled = false;
  // view.popup.actionsMenuEnabled = false;
  view.map.load();
  await watchUtils.whenEqualOnce(view.map, 'loadStatus', 'loaded');

  // Add the location widget
  const locateWidget = new Locate({ view, useHeadingEnabled: false });

  // Modify the view here
  view.ui.move('zoom', 'top-right');
  view.ui.add(locateWidget, {
    position: 'top-right',
  });

  const layer = getFacilitiesLayer(view.map);

  // Popup configuration
  const facilityPopup = new CustomContent({
    creator: function (event) {
      return new Promise((resolve, reject) => {
        getFacilityById({
          mapView: view,
          facilityId: event.graphic.attributes.OBJECTID,
        }).then(
          (graphic) => {
            if (
              graphic?.attributes !== undefined &&
              vaccineTypeFilter?.VaccineType === 'Monkeypox'
            ) {
              const monkAttributes = graphic?.services?.find(
                (service) => service?.ServiceType === 'Monkeypox'
              );
              if (monkAttributes) {
                graphic.attributes.Website = monkAttributes.Website;
                graphic.attributes.Phone = monkAttributes.Phone;
                graphic.attributes.Intake_WalkIn = monkAttributes.Intake_WalkIn;
                graphic.attributes.AdditionalInfo =
                  monkAttributes.AdditionalInfo;
              }
            }
            const component = (
              <>
                <LocationHeader
                  facility={graphic}
                  isPopup
                  onFacilitySelected={() => updateSelectedFacility(graphic)}
                  vaccineTypeFilter={vaccineTypeFilter}
                />
                <LocationFooter
                  selectedFacility={graphic}
                  focalPoint={focalPoint}
                  setPopupDirectionsRequest={setPopupDirectionsRequest}
                  vaccineTypeFilter={vaccineTypeFilter}
                  hideShadow
                  isPopup
                />
              </>
            );

            const element = document.createElement('div');
            element.id = 'facility_popup';

            ReactDOM.render(component, element);
            resolve(element);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      });
    },
  });

  const template = new PopupTemplate({
    content: [facilityPopup],
    overwriteActions: true,
    actions: [],
    actionsMenuEnabled: false,
    visibleElements: { closeButton: false, featureNavigation: false },
  });

  layer.watch('loadStatus', (e) => {
    if (e === 'loaded') {
      setTimeout(() => {
        setMapLoaded(true);
      }, 3000);
    }
  });

  layer.popupTemplate = template;

  view.popup.watch('visible', function (val, pVal, property, el) {
    const toggleElement = document.getElementById('mapToListToggle');
    if (!toggleElement) return;

    if (val) {
      // Update class to reposition popup
      toggleElement.style.visibility = 'hidden';
    } else {
      toggleElement.style.visibility = 'visible';
    }
  });

  // add a graphics layer to manage showing a custom graphic and symbol
  // for the currently selected facility
  const facilityGraphicsLayer = new GraphicsLayer({
    id: SELECTED_FACILITY_LAYER_ID,
  });
  view.map.layers.add(facilityGraphicsLayer);

  return { locateWidget };
};

const geolocationToPointGraphic = function (geolocation) {
  const { latitude, longitude } = geolocation.coords;
  return new Graphic({ geometry: new Point({ latitude, longitude }) });
};

const geometryToGeographic = function (pointGeometry) {
  // attempt to convert a point geometry into WKID 4326 if its spatial reference is in Web Mercator
  // NOTE: only supports Web Mercator to geographic WKID 4326 conversion
  // TODO: find out if we ever need to account for other conversions (likely not)
  let geographicPointGeometry = pointGeometry.clone();
  if (pointGeometry.spatialReference.isWebMercator) {
    geographicPointGeometry = webMercatorToGeographic(pointGeometry);
  }
  return geographicPointGeometry;
};

const getFacilityById = async function ({ mapView, facilityId }) {
  const facilitiesLayer = getFacilitiesLayer(mapView.map);
  const servicesLayer = getServicesLayer(mapView.map);

  const query = {
    cacheHint: true,
    objectIds: [facilityId],
    outFields: ['*'],
    returnGeometry: true,
    outSpatialReference: {
      wkid: 4326,
    },
  };
  const results = await facilitiesLayer.queryFeatures(query);
  const facility = results.features[0];

  // Get related services

  const servicesWhere = `LocationID = '${facility.attributes.LocationID}'`;
  const servicesQuery = {
    where: servicesWhere,
    cacheHint: true,
    outFields: ['*'],
    returnGeometry: false,
  };

  // Query for features that are currently displayed
  const services = await servicesLayer.queryFeatures(servicesQuery);

  facility.services = services.features.map(
    (relatedService) => relatedService.attributes
  );

  return facility;
};

const updateDisplayedFacilities = async function ({
  mapView,
  vaccineTypeFilter,
  attributeFilters,
  // spatialFilter,
  focalPoint,
  presetClause,
  mergeClause,
  returnGeometry = true,
  orderBy = [],
  ignoreMap,
}) {
  const facilitiesLayer = getFacilitiesLayer(mapView.map);
  const servicesLayer = getServicesLayer(mapView.map);

  //* For testing purposes *//
  // window.facilitiesLayer = facilitiesLayer;
  // window.mapView = mapView;

  //* Current workflow
  // 1. Set def expression on facilities layer
  // 2. Query facilities layer
  // 3. Sort facilities
  // 4. Return facilities

  //* Potential future workflow
  // 1. Query services layer
  // 2. Query facilities layer (for related features)
  // 3. "Join" services to facilities
  // 4. Set def expression on facilities layer
  // 5. Sort facilities
  // 6. Return facilities

  //*
  //* 1. Query services layer
  //*

  const servicesWhere = buildServiceWhereClause(
    vaccineTypeFilter,
    attributeFilters
  );
  const servicesQuery = {
    where: servicesWhere,
    cacheHint: true,
    outFields: ['*'],
    returnGeometry: false,
    maxRecordCountFactor: 5,
  };
  // Query for features that are currently displayed
  const services = await servicesLayer.queryFeatures(servicesQuery);

  //*
  //* 2. Query facilities layer (for related features)
  //*

  // Fetch facilities that belong to the services we fetched
  let facilitiesWhere;
  if (
    (presetClause && !mergeClause) ||
    vaccineTypeFilter.VaccineType === 'Flu'
  ) {
    facilitiesWhere = buildFacilityWhereClause(attributeFilters, services);
  } else {
    facilitiesWhere = buildFacilityWhereClause(
      attributeFilters,
      services,
      presetClause
    );
  }
  const facilitiesQuery = {
    where: facilitiesWhere,
    cacheHint: true,
    outFields: ['*'],
    returnGeometry: true,
    orderByFields: orderBy,
    outSpatialReference: {
      wkid: 4326,
    },
    maxRecordCountFactor: 5,
  };

  // Query for features that are currently displayed
  const facilities = await facilitiesLayer.queryFeatures(facilitiesQuery);
  //*
  //* 3. "Join" services to facilities
  //*
  facilities.features.forEach((facility) => {
    const id = facility.attributes.LocationID;

    // Filter services for those that match the current facility
    const relatedServices = services.features.filter(
      (service) => service.attributes.LocationID === id
    );

    // Add an array of service attribute objects to the facility
    facility.services = relatedServices.map(
      (relatedService) => relatedService.attributes
    );
  });

  //*
  //* 4. Set def expression on facilities layer
  //*

  // Apply a filter to the facilitiesLayer
  if (!ignoreMap) facilitiesLayer.definitionExpression = facilitiesWhere;

  //*
  //* 5. Sort facilities
  //*

  if (focalPoint) {
    // sort facilities before returning
    await sortFacilitiesByProximity({
      focalPoint,
      facilities: facilities.features,
    });
  }

  //*
  //* 6. Return facilities
  //*
  return facilities.features;
};

const goToFacilities = async function ({ mapView, features }) {
  if (features?.length) {
    return await mapView.goTo({ target: features, zoom: 14 });
  }
};

const togglePopup = function ({ mapView, feature }) {
  const graphicsLayer = mapView.map.findLayerById(SELECTED_FACILITY_LAYER_ID);
  graphicsLayer.graphics.removeAll();
  if (feature) {
    // if there is a selected facility then add a temporary graphic with the pin symbol
    const facilityGraphic = feature.clone();
    facilityGraphic.symbol = orangeRoundedPinSymbol;
    graphicsLayer.graphics.add(facilityGraphic);
    return;
  }

  // If no feature, close the popup
  mapView.popup.close();
};

const clearGraphics = function ({ mapView }) {
  const graphicsLayer = mapView.map.findLayerById(SELECTED_FACILITY_LAYER_ID);
  graphicsLayer.graphics.removeAll();
};

const selectFacilityInGraphicsLayer = async function ({
  mapView,
  setMapLoaded,
  feature,
  updateSelectedFacility,
  vaccineTypeFilter,
  focalPoint,
  setPopupDirectionsRequest,
}) {
  setMapLoaded(null);
  // lookup the graphics layer and clear out any previous graphics
  const graphicsLayer = mapView.map.findLayerById(SELECTED_FACILITY_LAYER_ID);
  graphicsLayer.graphics.removeAll();

  // adjust the labeling of the associated facilities feature layer by using LabelClass where clauses
  const featureLayer = getFacilitiesLayer(mapView.map);

  // Popup configuration
  const facilityPopup = new CustomContent({
    creator: function (event) {
      return new Promise((resolve, reject) => {
        getFacilityById({
          mapView: mapView,
          facilityId: event.graphic.attributes.OBJECTID,
        }).then(
          (graphic) => {
            if (
              graphic?.attributes !== undefined &&
              vaccineTypeFilter?.VaccineType === 'Monkeypox'
            ) {
              const monkAttributes = graphic?.services?.find(
                (service) => service?.ServiceType === 'Monkeypox'
              );
              if (monkAttributes) {
                graphic.attributes.Website = monkAttributes.Website;
                graphic.attributes.Phone = monkAttributes.Phone;
                graphic.attributes.Intake_WalkIn = monkAttributes.Intake_WalkIn;
                graphic.attributes.AdditionalInfo =
                  monkAttributes.AdditionalInfo;
              }
            }
            const component = (
              <>
                <LocationHeader
                  facility={graphic}
                  isPopup
                  onFacilitySelected={() => updateSelectedFacility(graphic)}
                  vaccineTypeFilter={vaccineTypeFilter}
                />
                <LocationFooter
                  selectedFacility={graphic}
                  focalPoint={focalPoint}
                  setPopupDirectionsRequest={setPopupDirectionsRequest}
                  vaccineTypeFilter={vaccineTypeFilter}
                  hideShadow
                  isPopup
                />
              </>
            );

            const element = document.createElement('div');
            element.id = 'facility_popup';

            ReactDOM.render(component, element);
            resolve(element);
          },
          (error) => {
            console.error(error);
            reject(error);
          }
        );
      });
    },
  });

  const template = new PopupTemplate({
    content: [facilityPopup],
    overwriteActions: true,
    actions: [],
    actionsMenuEnabled: false,
    visibleElements: { closeButton: false, featureNavigation: false },
  });

  if (featureLayer.loadStatus === 'loaded') {
    setTimeout(() => {
      setMapLoaded(true);
    }, 3000);
  }

  featureLayer.popupTemplate = template;

  mapView.popup.watch('visible', function (val, pVal, property, el) {
    const toggleElement = document.getElementById('mapToListToggle');
    if (!toggleElement) return;

    if (val) {
      // Update class to reposition popup
      toggleElement.style.visibility = 'hidden';
    } else {
      toggleElement.style.visibility = 'visible';
    }
  });

  if (featureLayer?.labelingInfo) {
    // if there is a selected facility feature then hide the specific label for that feature,
    // otherwise show all labels throughout the feature layer
    const whereClause = feature
      ? `${featureLayer.objectIdField} NOT IN (${
          feature.attributes[featureLayer.objectIdField]
        })`
      : '1=1';

    // the feature layer's labelingInfo is an array of LabelClass
    // each needs to be cloned, adjusted, and reassigned for the feature layer to pick up the changes
    featureLayer.labelingInfo.forEach((labelClass, index) => {
      const lcClone = labelClass.clone();
      lcClone.where = whereClause;
      featureLayer.labelingInfo[index] = lcClone;
    });
    featureLayer.labelingInfo = featureLayer.labelingInfo;
  }
};

const sortFacilitiesByProximity = async function ({ focalPoint, facilities }) {
  if (focalPoint && facilities?.length) {
    // Get the distances of each feature from the focal point
    facilities.forEach((facility) => {
      // TODO: figure out how to get these in miles so we can display e.g. "0.5 miles away"
      // const distance = focalPoint.geometry.distance(facility.geometry);

      //* NOTE: in theory the following should work for calculating distances in miles, but initial tests sometimes showed potentially incorrect ascending ordering.
      const distance = getDistanceFromFacility(focalPoint, facility);

      facility.proximity = distance;
    });

    // Sort the array of features by distance (ascending)
    facilities.sort((a, b) => a.proximity - b.proximity);
    return;
  }
};

const getDistanceFromFacility = (focalPoint, facility) => {
  if (!focalPoint?.geometry || !facility?.geometry) {
    // If either point doesn't have a geometry, return null
    return null;
  }

  return geometryEngine.geodesicLength(
    new Polyline({
      paths: [
        [
          [focalPoint.geometry.longitude, focalPoint.geometry.latitude],
          [facility.geometry.longitude, facility.geometry.latitude],
        ],
      ],
      spatialReference: {
        wkid: 4326,
      },
    }),
    'miles'
  );
};
const buildServiceWhereClause = (vaccineType, filters) => {
  let clauses = [];
  let vaccineFilter = `ServiceType like '%${vaccineType.VaccineType}%'`;
  if (filters && Object.keys(filters).length) {
    Object.entries(filters).forEach(([key, values]) => {
      if (values && typeof values === 'object' && key !== 'ADA_Compliant') {
        if (values.length) {
          clauses.push(
            `${values.map((value) => `${key} = '${value}'`).join(' OR ')}`
          );
        }
      } else if (values && key !== 'ADA_Compliant') {
        clauses.push(`${key} = '${values}'`);
      }
    });
    clauses = clauses.map((clause) => `(${clause})`);
    if (!!clauses.length) {
      return vaccineFilter + ' AND ' + clauses.join(' AND ');
    } else {
      return vaccineFilter;
    }
  } else {
    return vaccineFilter;
  }
  return `1=1`;
};

const buildFacilityWhereClause = (filters, services, presetClause) => {
  let clause = `LocationID IN ('${services.features
    .map((f) => f.attributes.LocationID)
    .join("','")}')`;
  if (presetClause) {
    clause += ` AND ${presetClause}`;
  }
  if (filters?.ADA_Compliant) {
    clause += ` AND ADA_Compliant = 'Yes'`;
    return clause;
  }

  return clause;
};

const getFacilitiesLayer = (map) => {
  return getLayerByTitle(map, FACILITIES_LAYER_TITLE);
};

const getServicesLayer = (map) => {
  return getLayerByTitle(map, SERVICES_LAYER_TITLE);
};

const getLayerByTitle = (map, title) => {
  const layer = map.layers.find((layer) => layer.title === title);
  return layer;
};

const getNoticeTable = async () => {
  var queryTask = new QueryTask({
    url: getNoticeServerTableUrl(),
  });
  var noticeQuery = new Query();
  noticeQuery.outFields = ['Notice_text'];
  noticeQuery.where =
    "(Start_time <= TIMESTAMP '" +
    new Date().toISOString().split('.')[0].replace('T', ' ') +
    "') and (End_time > TIMESTAMP '" +
    new Date().toISOString().split('.')[0].replace('T', ' ') +
    "')";
  try {
    let results = await queryTask.execute(noticeQuery);
    let noticeResults = results.features.map((e) => {
      return e.attributes.Notice_text;
    });
    return noticeResults;
  } catch {
    console.error('Error Getting Banner Notice From Notice Service');
    return [];
  }
};

const closePopUp = (mapView) => {
  mapView.popup.close();
};

export {
  initMapView,
  geolocationToPointGraphic,
  geometryToGeographic,
  getFacilityById,
  updateDisplayedFacilities,
  goToFacilities,
  togglePopup,
  selectFacilityInGraphicsLayer,
  sortFacilitiesByProximity,
  getDistanceFromFacility,
  getNoticeTable,
  closePopUp,
  clearGraphics,
};
