import React from 'react';
import Helmet from 'react-helmet';

import { isFluSeason, isMonkPoxSeason } from 'constants/map';

const MetaTags = () => {
  let title = 'NYC Vaccine Finder';

  if (isFluSeason && !isMonkPoxSeason)
    title = 'NYC COVID-19 and Flu Vaccine Finder';
  else if (!isFluSeason && !isMonkPoxSeason)
    title = 'NYC COVID-19 Vaccine Finder';
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content="Find a vaccine near you." />
      {/* <meta name="theme-color" content="#000000" /> */}
      {/* Open Graph */}
      {/* <meta property="og:url" content="%PUBLIC_URL%" /> */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content="Find a vaccine near you." />
      <meta
        property="og:image"
        content="http://www1.nyc.gov/assets/home/images/content/home/program-nyc-darkblue.png"
      />
      {/* Twitter Card */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content="Find a vaccine near you." />
      <meta
        name="twitter:image"
        content="http://www1.nyc.gov/assets/home/images/content/home/program-nyc-darkblue.png"
      />
      <meta name="twitter:image:alt" content="New York City Logo" />
    </Helmet>
  );
};

export default MetaTags;
