import styled, { css } from 'styled-components';
import { FloatingButton } from 'components/baseComponents';
import { StyledFooter } from 'components/Footer/Footer-styled';
import { StyledHeader } from 'components/Header/Header-styled';

export const StyledAppView = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.hide &&
    css`
      position: fixed;
      top: -500vh;
      top: calc(var(--vh, 1vh) * -500);
    `};

  ${StyledHeader} {
    height: 53px;
  }
`;

export const StyledHeaderContainer = styled.div`
  background: ${(props) => props.theme.palette.colors.white};
  padding: 0 0.775rem;
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
  }

  ${(props) =>
    !props.showFilter &&
    css`
      visibility: hidden;
      height: 0;
      padding 0;
    `};
`;

export const StyledContentMapContainer = styled.main`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  overflow-y: auto;
  height: calc(100vh - 98px);

  @media screen and (max-width: 650px) {
    ${(props) =>
      props.filterVisible &&
      css`
        z-index: 2;
      `};
  }
`;

export const StyledContentContainer = styled.div`
  position: relative;
  flex: 0 0 ${(props) => props.theme.global.sidebarWidth};
  height: 100%;
  /* overflow-y: auto; */
  background-color: ${(props) => props.theme.palette.colors.offWhite};

  @media screen and (max-width: 650px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    ${(props) =>
      props.mView === 'map' &&
      !props.filterVisible &&
      css`
        display: none;
      `};

    ${StyledFooter} {
      position: fixed;
    }
  }
`;

export const StyledMapContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100vw - ${(props) => props.theme.global.sidebarWidth});
  flex: 1 0 auto;
  height: 100%;
  min-width: 200px;
  background: #a7d6fe;

  /* Map styles that can't be directly in a styled component Map */
  .map-container {
    height: 100%;
    width: 100%;

    ${(props) =>
      !props.hasMapView &&
      css`
        visibility: hidden;
        position: absolute;
        top: 0;
      `};
  }

  @media screen and (max-width: 650px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  ${FloatingButton} {
    display: none;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 650px) {
      display: block;

      ${(props) =>
        props.mView !== 'map' &&
        css`
          display: none;
        `};
    }
  }
`;
