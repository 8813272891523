import styled, { css } from 'styled-components';
import { CalciteH2, CalciteP } from 'calcite-react/Elements';
import CustomTheme from '../../CustomTheme';
import { Tooltip } from 'react-accessible-tooltip';

export const StyledCalciteP = styled(CalciteP)`
  display: flex;
  line-height: 1.2rem !important;
  margin: 0;
  align-items: center;

  > svg {
    margin-inline-end: ${CustomTheme.global.margin};
    display: none;
  }

  span {
    margin-inline: 0;
  }
`;

export const StyledCalciteH2 = styled(CalciteH2)`
  margin: 0;
  font-size: 1rem;
  font-weight: 600 !important;
  line-height: 1.55rem !important;
`;

export const StyledLocationHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;

  ${(props) =>
    props.isPopup &&
    css`
      min-height: 95px;
    `};
  ${StyledCalciteP} {
    font-size: 0.8rem;
  }
`;

export const StyledTitleContainer = styled.div`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;

  ${StyledCalciteH2} {
    /* display: inline-flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 600 !important;
    line-height: 1.55rem !important;

    img {
      margin-inline-start: 0.25rem;
    }

    ${(props) =>
      props.isPopup &&
      css`
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: ${CustomTheme.palette.colors.button};
        cursor: pointer;

        &:hover {
          color: ${CustomTheme.palette.colors.buttonHover};
        }
      `};
  }

  mark {
    font-size: 0.8rem;
    line-height: 1rem;
    background-color: inherit;
    padding: 0;
    border-radius: inherit;
    color: ${CustomTheme.palette.colors.offBlack};
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.isPopup &&
    css`
      width: 100%;
    `};
`;

export const StyledShortcutContainer = styled.div`
  display: flex;
  align-items: flex-start;

  button,
  a {
    margin-inline-start: 0.5rem;
  }
`;

export const StyledLocationContactContainer = styled.div`
  font-size: 0.8rem;
  margin-bottom: 0.3rem;

  a {
    color: ${CustomTheme.palette.blue};
  }

  p {
    margin-bottom: 0;
    line-height: 1.55rem;
  }

  span {
    margin-inline: 1rem;
  }
`;

export const StyledTypeProximityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledProximityLabel = styled.span`
  font-size: 0.8rem;
  line-height: 1rem;
  background-color: inherit;
  padding: 0;
  border-radius: inherit;
  color: ${CustomTheme.palette.colors.offBlack};
`;

export const Highlight = styled.span`
  color: ${CustomTheme.palette.colors.accent3};
  margin-inline: 0 !important;
  font-size: 0.88rem;
  font-weight: bold;

  ${(props) =>
    props.isPositive &&
    css`
      color: ${CustomTheme.palette.colors.successAccessible};
      font-size: 0.88rem;
      font-weight: bold;
    `};

  ${(props) =>
    props.isNegative &&
    css`
      color: ${CustomTheme.palette.colors.fail};
      font-size: 0.88rem;
      font-weight: bold;
    `};
`;

export const StyledParagraph = styled.div`
  display: flex;
  line-height: 1.2rem !important;
  margin: 0;
  align-items: self-start;

  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};

  > svg {
    margin-inline-end: ${CustomTheme.global.margin};
    display: none;
  }

  span {
    margin-inline: 0;
  }

  ol {
    line-height: inherit;
  }
`;

export const StyledParagraphSecondary = styled.div`
  display: flex;
  line-height: 1.2rem !important;
  margin: 0;
  align-items: self-start;
  color: ${CustomTheme.palette.colors.secondary};

  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};

  > svg {
    margin-inline-end: ${CustomTheme.global.margin};
    display: none;
  }

  span {
    margin-inline: 0;
  }

  ol {
    line-height: inherit;
  }
`;

export const StyledAvailabilityLabel = styled(CalciteP)`
  font-weight: bold;
  color: ${CustomTheme.palette.green};

  ${(props) =>
    props.availability === 'NOTAVAILABLE' &&
    css`
      color: ${CustomTheme.palette.red};
    `};
`;

export const StyledTooltip = styled(Tooltip)`
  & {
    display: inline-block;
    position: relative;
  }

  .tooltip-label {
    display: inline-block;
    position: relative;
  }

  .tooltip__overlay {
    display: block;
    position: absolute;
    color: #fff;
    background: #000000a6;
    border-radius: 10px;
    padding: 10px 10px;
    font-weight: 300;
    z-index: 1;
    left: -10px;
  }

  .tooltip__overlay--hidden {
    display: none;
  }

  .tooltip__overlay--contained {
    max-width: 200px;
  }
`;
