// import SearchViewModel from '@arcgis/core/widgets/Search/SearchViewModel';
import Search from '@arcgis/core/widgets/Search';
import Locator from '@arcgis/core/tasks/Locator';
import { pushpinSymbol } from 'constants/map';

const initSearchWidget = async ({ mapView, ref, searchTerm }) => {
  const search = new Search({
    view: mapView,
    includeDefaultSources: false,
    container: ref.current,
    locationEnabled: false,
    popupEnabled: false,
    searchTerm,
    sources: [
      {
        locator: new Locator({
          url:
            'https://utility.arcgis.com/usrsvcs/servers/e6184796e86e423c87beacf936244add/rest/services/World/GeocodeServer',
        }),
        singleLineFieldName: 'SingleLine',
        outFields: ['Addr_type'],
        name: 'NYC Locator',
        placeholder: 'Search address or ZIP',
        resultSymbol: pushpinSymbol,
        zoomScale: 72223.819286, // = level 13 (https://www.esri.com/arcgis-blog/products/product/mapping/web-map-zoom-levels-updated/)
      },
    ],
  });

  return search;
};

export { initSearchWidget };
