import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';

// is this a web map/scene?
function isItem(map) {
  return typeof map === 'string' || !!map.baseMap || !!map.operationalLayers;
}

export function loadView(map, options) {
  return isItem(map) ? loadItem(map, options) : loadMap(map, options);
}

export function loadMap(mapProperties, options) {
  // then we create a map (or scene)
  const map = new Map({ ...mapProperties });
  // and return a view with that map (or scene)
  const { view } = options;
  return new MapView({
    ...view,
    map,
  });
}

export async function loadItem(item, options) {
  // then we create a wem map (or scene) from the item
  const map =
    typeof item === 'string'
      ? new WebMap({
          portalItem: {
            id: item,
          },
        })
      : WebMap.fromJSON(item);
  // and return a view with that web map (or scene)
  const { view } = options;

  await map.load();

  const facilitiesLayer = map.layers.find(
    (layer) => layer.title === 'Facilities'
  );
  const servicesLayer = map.layers.find((layer) => layer.title === 'Services');

  servicesLayer.outFields = ['*'];
  facilitiesLayer.outFields = ['*'];

  return new MapView({
    ...view,
    map,
  });
}

export function destroyView(view) {
  if (!view) {
    return;
  }
  // undocumented way to destroy a view
  view = view.container = null;
}
