// Framework and third-party non-ui
import React, { useState, useRef, useEffect, useContext } from 'react';

// Hooks, context, and constants
import { AppContext } from 'contexts/AppContext';

// App Components
import { initSearchWidget } from './SearchController';

// JSON & Styles
import {
  StyledSearchContainer,
  StyledPlaceholderTextField,
} from './Search-styled';

// Third-party components (buttons, icons, etc.)

const Search = () => {
  const [, setReady] = useState(false);
  const [searchWidget, setSearchWidget] = useState(null);
  const ref = useRef(null);
  const {
    mapView,
    searchTerm,
    updateSearchTerm,
    updateFocalPoint,
  } = useContext(AppContext);

  useEffect(() => {
    const init = async function () {
      if (mapView) {
        // Initialize the Search widget
        const Search = await initSearchWidget({ mapView, ref, searchTerm });
        // Save to local state
        setSearchWidget(Search);

        Search.on('search-complete', function (event) {
          updateSearchTerm(event.searchTerm);

          const focalPointGraphic = event.results[0].results[0].feature;
          // add the address string to the search result graphic's attributes
          // which will be used by <DirectionsButton>
          focalPointGraphic.attributes.Address =
            event.results[0].results[0].name;
          updateFocalPoint({
            graphic: focalPointGraphic,
          });

          // HACK to avoid multiple search result pins on the map
          if (window.searchWidget && window.searchWidget.id !== Search.id) {
            window.searchWidget.destroy();
          }
          window.searchWidget = Search;
        });

        Search.on('search-clear', function () {
          updateSearchTerm('');
          updateFocalPoint({});
        });

        // Set local state to ready
        setReady(true);
      }
    };
    init();
  }, [mapView]);

  useEffect(() => {
    if (searchWidget && searchTerm) {
      searchWidget.searchTerm = searchTerm;
    }
  }, [searchWidget, searchTerm]);

  return (
    <StyledSearchContainer className="notranslate" content="notranslate">
      <span ref={ref} />
      {!mapView && (
        <StyledPlaceholderTextField placeholder="Loading locations..." />
      )}
    </StyledSearchContainer>
  );
};

export default Search;
