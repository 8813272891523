import styled, { css } from 'styled-components';

import Button from 'calcite-react/Button';

export const IconButton = styled(Button).attrs((attrs) => {
  return {
    ...attrs,
  };
})`
  background: ${(props) => props.theme.palette.colors.button};
  border-radius: ${(props) => props.theme.global.borderRadius};
  padding: 0.31rem 0.62rem;
  color: ${(props) => props.theme.palette.white} !important;
  text-decoration: none;
  margin: 0;

  :hover {
    background: ${(props) => props.theme.palette.colors.buttonHover};
  }

  ${(props) =>
    props.extraSmall &&
    css`
      padding-inline-start: 1rem;
      padding-inline-end: 1rem;
    `};

  ${(props) =>
    props.large &&
    css`
      width: 36px;
      height: 36px;
    `};

  ${(props) =>
    props.floating &&
    css`
      box-shadow: ${(props) => props.theme.boxShadow};
    `};

  ${(props) =>
    props.hasMargins &&
    css`
      padding-inline-start: ${(props) => props.theme.global.padding};
      padding-inline-end: ${(props) => props.theme.global.padding};

      > svg {
        margin-inline-end: ${(props) => props.theme.global.margin} !important;
      }
    `};
`;
