// Framework and third-party non-ui
import React, { useContext } from 'react';

// Hooks
import { useQuery } from 'hooks/useQuery';
import { useWebMap } from 'hooks/useWebMap';
import { useWatch } from 'hooks/useWatch';

// Context
import { AppContext } from 'contexts/AppContext';

// App components
import MapLoader from './MapLoader';
import { initMapView } from 'utils/arcgis';
import { getWebmapId } from 'utils/helpers';
import MapListToggle from 'components/MapListToggle';

// JSON & Styles
import { Route, useRouteMatch } from 'react-router-dom';

// Third-party components (buttons, icons, etc.)

const Map = () => {
  const {
    mapView,
    updateMapView,
    setLocateWidget,
    mapLoaded,
    setMapLoaded,
    focalPoint,
    updateFocalPoint,
    vaccineTypeFilter,
    updateSelectedFacility,
    setPopupDirectionsRequest,
  } = useContext(AppContext);
  const query = useQuery();
  const urlWebmapId = query.get('webmap');

  // Set the webmapId to the query param if it exists, otherwise whatever is configured in constants based on current app_env
  const webmapId = urlWebmapId || getWebmapId();
  const [ref, view] = useWebMap(webmapId, {
    view: {
      constraints: {
        rotationEnabled: false,
      },
    },
  });

  const handleMapReady = async () => {
    try {
      const { locateWidget } = await initMapView({
        view,
        setMapLoaded,
        updateSelectedFacility,
        vaccineTypeFilter,
        setPopupDirectionsRequest,
        focalPoint,
      });

      // Update focal point when locate widget is used
      locateWidget.on('locate', (event) => {
        updateFocalPoint({ position: event.position });
      });

      // Set map + widgets in AppContext
      updateMapView(view);
      setLocateWidget(locateWidget);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAriaComponents = (routeMatch) => {
    let ariaComponents = [
      '.esri-view-surface',
      'div[title="Zoom in"]',
      'div[title="Zoom out"]',
      '.esri-attribution__sources',
      '.esri-attribution__link',
      '.esri-locate',
    ];
    ariaComponents.forEach((componentSelector) => {
      if (document.querySelector(componentSelector)) {
        document
          .querySelector(componentSelector)
          .setAttribute('tabIndex', routeMatch ? '0' : '-1');
      }
    });
  };

  useWatch(view, 'ready', handleMapReady);

  let match = useRouteMatch(
    '/locations/' +
      (vaccineTypeFilter.VaccineType === 'Monkeypox'
        ? 'Mpox'
        : vaccineTypeFilter.VaccineType)
  );
  handleAriaComponents(match);

  return (
    <>
      <a className="skip-link" href="#skip-link-map">
        Skip over map
      </a>
      {!mapLoaded ? <MapLoader /> : null}
      <div className="map-container" ref={ref} data-testid="Map"></div>
      <span id="skip-link-map"></span>
      <Route
        path={
          '/locations/' +
          (vaccineTypeFilter.VaccineType === 'Monkeypox'
            ? 'Mpox'
            : vaccineTypeFilter.VaccineType)
        }
      >
        <MapListToggle id="mapToListToggle" aria-hidden={!!match} />
      </Route>
    </>
  );
};

export default Map;
