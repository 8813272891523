// Framework and third-party non-ui
import React from 'react';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledIconButton } from './ShareButton-styled';

// App components

// Third-party components (buttons, icons, etc.)
import CopyToClipboard from 'calcite-react/CopyToClipboard';

import ShareIcon from '../../assets/share.svg';
import ShareIconIos from 'calcite-ui-icons-react/ShareIosIcon';
import ShareIconAndroid from 'calcite-ui-icons-react/ShareIcon';
import { isAndroid, isIOS, isMacOs } from 'react-device-detect';

// JSON

// CSS

const ShareButton = ({ selectedFacility, vaccineType }) => {
  // const [shareModalOpen, setShareModalOpen] = useState(false);
  // const { id } = useParams();
  let icon = null;
  if (isAndroid) {
    icon = <ShareIconAndroid size={16} />;
  } else if (isIOS || isMacOs) {
    icon = <ShareIconIos size={16} />;
  } else {
    icon = <img src={ShareIcon} alt="share" />;
  }

  const locationHref = `${
    window.location.href.split('/locations')[0]
  }/locations/${vaccineType}/${selectedFacility?.attributes?.OBJECTID}`;

  // Need to change this based on the route?
  // const locationId = id;
  // const modalTitle = locationId
  //   ? 'Share this location'
  //   : 'Share NYC Vaccine Finder';

  const onShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: locationHref,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      // setShareModalOpen(true);
    }
  };
  const attributes = selectedFacility?.attributes || [];
  const ariaLabel = 'Share ' + attributes?.FacilityName || '';

  if (navigator.share) {
    return (
      <StyledIconButton
        floating
        large
        white
        icon={icon}
        iconButton
        onClick={onShareClick}
        aria-label={ariaLabel}
      />
    );
  }

  return (
    <CopyToClipboard
      copyValue={locationHref}
      tooltip="Copy share link to clipboard"
    >
      <StyledIconButton
        iconButton
        floating
        large
        white
        icon={icon}
        aria-label="Share"
      />
    </CopyToClipboard>
  );
};

ShareButton.propTypes = {};

ShareButton.defaultProps = {};

export default ShareButton;
