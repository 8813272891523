import styled from 'styled-components';

import { FloatingButton } from 'components/baseComponents';

export const StyledFloatingButton = styled(FloatingButton)`
  height: 2.5rem;
  font-size: 1rem;
  padding-inline: 1.55rem;
  line-height: 1;
  background: ${(props) => props.theme.palette.colors.button};
  border: ${(props) => props.theme.palette.colors.button};

  :hover {
    background: ${(props) => props.theme.palette.colors.buttonHover};
  }
`;
