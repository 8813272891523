// Framework and third-party non-ui
import React from 'react';
import PropTypes from 'prop-types';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledFooter } from './Footer-styled';

// App components

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const Footer = ({ children, ...props }) => {
  return <StyledFooter {...props}>{children}</StyledFooter>;
};

Footer.propTypes = {
  noPosition: PropTypes.bool,
};

Footer.defaultProps = {};

export default Footer;
