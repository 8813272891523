// Framework and third-party non-ui
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, useParams } from 'react-router-dom';

// Redux operations and local helpers/utils/modules
import { isEmail } from 'utils/helpers';

// Component specific modules (Component-styled, etc.)
import CustomTheme from 'CustomTheme';
import { StyledLocationFooter } from './LocationFooter-styled';

// App components
import ShareButton from 'components/ShareButton';
import DirectionsButton from 'components/DirectionsButton';
import { IconButton } from 'components/baseComponents';

// Third-party components (buttons, icons, etc.)
import CalciteThemeProvider from 'calcite-react/CalciteThemeProvider';
import PhoneIcon from 'calcite-ui-icons-react/PhoneIcon';
import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';

// JSON

// CSS

const LocationFooter = ({
  children,
  selectedFacility,
  focalPoint,
  hideShadow,
  setPopupDirectionsRequest,
  vaccineTypeFilter,
  isPopup,
  ...props
}) => {
  // Some websites are email addresses... prepend mailto: if thats the case

  let scheduleHref = selectedFacility?.attributes?.Website;

  // let scheduleHref =
  //   vaccineTypeFilter?.VaccineType === 'Monkeypox'
  //     ? 'https://vax4nyc.nyc.gov/monkeypox'
  //     : selectedFacility?.attributes?.Website;

  if (scheduleHref && isEmail(scheduleHref)) {
    scheduleHref = `mailto:${scheduleHref}`;
  }

  return (
    <CalciteThemeProvider theme={CustomTheme}>
      <StyledLocationFooter
        hideShadow={hideShadow}
        isPopup={isPopup}
        style={{
          alignItems: 'stretch',
        }}
      >
        {scheduleHref && (
          <IconButton
            href={scheduleHref}
            target="_BLANK"
            fullWidth
            icon={<CalendarIcon size={16} />}
            iconPosition="before"
          >
            Schedule appointment
          </IconButton>
        )}
        {selectedFacility?.attributes?.Phone && !scheduleHref && (
          <IconButton
            href={`tel:${selectedFacility.attributes.Phone}`}
            icon={<PhoneIcon size={16} />}
            fullWidth
            iconPosition="before"
          >
            Call for appointment
          </IconButton>
        )}
        {selectedFacility?.attributes?.Address && (
          <DirectionsButton
            facility={selectedFacility}
            focalPoint={focalPoint}
            setPopupDirectionsRequest={setPopupDirectionsRequest}
            iconOnly={true}
          />
        )}
        <ShareButton
          selectedFacility={selectedFacility}
          vaccineType={vaccineTypeFilter?.VaccineType}
        />
      </StyledLocationFooter>
    </CalciteThemeProvider>
  );
};

LocationFooter.propTypes = {
  selectedFacility: PropTypes.object.isRequired,
  focalPoint: PropTypes.object,
  hideShadow: PropTypes.bool,
  setPopupDirectionsRequest: PropTypes.func,
};

LocationFooter.defaultProps = {};

export default LocationFooter;
