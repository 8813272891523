import styled from 'styled-components';

import { StyledFooter } from 'components/Footer/Footer-styled';
import { FloatingButton } from 'components/baseComponents';
import { FormControl } from 'calcite-react/Form';
import { StyledCheckboxLabel } from 'calcite-react/Checkbox/Checkbox-styled';

export const StyledFilterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  background: ${(props) => props.theme.palette.colors.white};
  box-sizing: border-box;

  ${StyledFooter} ${FloatingButton} {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }
  }

  @media screen and (max-width: 650px) {
    position: fixed;
  }
`;

export const StyledFilterSectionContainer = styled.div`
  padding: ${(props) => props.theme.global.padding};
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.offWhite};

  h5 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.75rem;
  }
`;

export const StyledFilterGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledFormControl = styled(FormControl)`
  margin: 0;
  margin-inline-start: -0.125rem;

  legend {
    margin: 0.5rem 0 0.125rem;
    font-size: 0.9rem;
    min-width: 180px;
  }

  ${StyledCheckboxLabel} {
    font-size: 0.8rem;
  }
`;
