import styled, { css } from 'styled-components';

export const StyledMap = styled.div`
  height: 100%;
  width: 100%;

  ${(props) =>
    !props.hasMapView &&
    css`
      visibility: hidden;
      position: absolute;
      top: 0;
    `};
`;

export const StyledMapLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.blue};
`;
