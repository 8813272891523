import styled from 'styled-components';
import {
  StyledAccordion,
  StyledAccordionTitle,
} from 'calcite-react/Accordion/Accordion-styled';

export const StyledHomeFAQContainer = styled.div`
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
`;
export const StyledHomeFAQDropdownContainer = styled.div`
  ${StyledAccordion} {
    width: 100%;
    margin-top: 15px;
    &:last-child {
      margin-bottom: 5rem;
    }
    ${StyledAccordionTitle} {
      font-size: 1rem;
      border-radius: 0;
      svg {
        min-height: 16px;
        min-width: 16px;
      }
      :hover {
        background-color: ${(props) => props.theme.palette.colors.offWhite};
      }
    }
  }
`;

export const DisclaimerContainer = styled.div`
  border-top: 1px solid #eaeaea;
`;
