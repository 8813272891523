// Framework and third-party non-ui
import React from 'react';

// JSON & Styles
import { StyledMapLoaderContainer } from './Map-styled';
// import { GlobeIcon } from 'calcite-ui-icons-react/GlobeIcon';
import Loader from 'calcite-react/Loader';

const MapLoader = () => {
  return (
    <StyledMapLoaderContainer>
      <Loader color="#095796" sizeRatio={1.2} />
    </StyledMapLoaderContainer>
  );
  // return (
  //   <>
  //     <StyledMapLoader>
  //       <LoaderGlobeWrapper>
  //         <GlobeIcon />
  //       </LoaderGlobeWrapper>
  //     </StyledMapLoader>
  //   </>
  // );
};

export default MapLoader;
