import styled from 'styled-components';
import { CalciteH2 } from 'calcite-react/Elements';

export const StyledPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.global.padding};
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.offWhite};
  box-sizing: border-box;
`;

export const StyledCalciteH2 = styled(CalciteH2)`
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600 !important;
  line-height: 1.55rem !important;
`;
