import styled, { css } from 'styled-components';

import { CalciteOl, CalciteH3 } from 'calcite-react/Elements';

export const StyledLocationDetailSectionContainer = styled.div`
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.palette.white};
  margin: 0.5rem 0;
  border-radius: 6px;

  h5 {
    font-size: 1rem;
    margin: 0;
  }

  ${(props) =>
    props.hasTitle &&
    css`
      margin-bottom: -1px;
      padding: 0.25rem 1rem;
    `};
`;

export const StyledFieldValuesContainer = styled(CalciteOl)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  font-size: 0.8rem;

  ${(props) =>
    props.paragraphContent &&
    css`
      padding-inline-start: calc(16px + 0.25rem);
    `};

  li {
    display: inline-flex;
    align-items: center;
    margin-inline-end: 1.25rem;

    svg {
      margin-inline-end: 0.25rem;
      color: #288369;
    }
  }

  ${(props) =>
    props.stackedContent &&
    css`
      flex-direction: column;

      li {
        border-bottom: 1px solid ${(props) => props.theme.palette.lightestGray};
        margin: 0 -1rem;
        margin-inline-end: 0;
        padding: 0.3875rem 0 0.3875rem 1rem;
        width: calc(100% + 1rem);

        &:last-child {
          border-bottom: none;
        }
      }
    `};
`;

export const StyledCalciteH3 = styled(CalciteH3)`
  display: inline-flex;
  font-size: 1rem;
  margin: 0.25rem 0 0 0;
  line-height: 1;
  color: ${(props) => props.theme.palette.colors.primary};

  svg {
    margin-inline-end: 0.25rem;
  }
`;
