import styled from 'styled-components';

import { StyledFooter } from 'components/Footer/Footer-styled';
import { StyledLocationHeaderContainer } from 'components/LocationHeader/LocationHeader-styled';

export const StyledListContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0.5rem 0 4rem 0;
  background: ${(props) => props.theme.palette.offWhite};
  overflow-y: auto;
  box-sizing: border-box;

  ${StyledFooter} > * {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }
  }

  ${StyledLocationHeaderContainer} {
    padding-inline-start: 1rem;
    background: ${(props) => props.theme.palette.white};
    border-radius: 6px;
  }
`;
