// Framework and third-party non-ui
import React from 'react';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledMessage, StyledCalciteP } from './Message-styled';

// App components

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const Message = ({ children, ...props }) => {
  return (
    <StyledMessage {...props}>
      <StyledCalciteP>
        Keep checking the site! New appointments, providers and locations are
        regularly added.
      </StyledCalciteP>
    </StyledMessage>
  );
};

export default Message;
