// Framework and third-party non-ui
import React, { useContext, useState } from 'react';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledFilterContainer,
  StyledFilterSectionContainer,
  StyledFilterGroupsContainer,
  StyledFormControl,
} from './FilterView-styled';

// App components
import PanelHeader from 'components/PanelHeader';
import Footer from 'components/Footer';
import { FloatingButton } from 'components/baseComponents';
import { Fieldset } from 'calcite-react/Form';
import Checkbox from 'calcite-react/Checkbox';

// Third-party components (buttons, icons, etc.)

// JSON
import strings from 'strings/en.json';

// CSS

const FilterView = (props) => {
  const {
    attributeFilters,
    updateAttributeFilters,
    setFilterVisible,
  } = useContext(AppContext);
  const [draftFacilityTypeFilters, setDraftFacilityTypeFilters] = useState(
    attributeFilters.facilityType || []
  );

  const applyFilter = () => {
    updateAttributeFilters({ facilityType: draftFacilityTypeFilters });
    setFilterVisible(false);
  };

  const updateDraftFacilityTypeFilters = ({ value, checked }) => {
    if (checked) {
      setDraftFacilityTypeFilters([...draftFacilityTypeFilters, value]);
    } else {
      setDraftFacilityTypeFilters(
        draftFacilityTypeFilters.filter((activeField) => activeField !== value)
      );
    }
  };

  return (
    <StyledFilterContainer>
      <PanelHeader onClose={() => setFilterVisible(false)}>Filters</PanelHeader>
      <StyledFilterSectionContainer>
        <StyledFilterGroupsContainer>
          <StyledFormControl>
            <Fieldset name="facilityType">
              <Checkbox
                name="CommunityHealthCenter"
                checked={draftFacilityTypeFilters?.includes(
                  'CommunityHealthCenter'
                )}
                onChange={(e) => {
                  updateDraftFacilityTypeFilters(
                    'CommunityHealthCenter',
                    e.target.checked
                  );
                }}
              >
                {strings.CommunityHealthCenter}
              </Checkbox>
              <Checkbox
                name="HospitalBasedHealthCenter"
                checked={draftFacilityTypeFilters?.includes(
                  'HospitalBasedHealthCenter'
                )}
                onChange={(e) => {
                  updateDraftFacilityTypeFilters(
                    'HospitalBasedHealthCenter',
                    e.target.checked
                  );
                }}
              >
                {strings.HospitalBasedHealthCenter}
              </Checkbox>
              <Checkbox
                name="Pharmacy"
                checked={draftFacilityTypeFilters?.includes('Pharmacy')}
                onChange={(e) => {
                  updateDraftFacilityTypeFilters('Pharmacy', e.target.checked);
                }}
              >
                {strings.Pharmacy}
              </Checkbox>
              <Checkbox
                name="PrimaryCare"
                checked={draftFacilityTypeFilters?.includes('PrimaryCare')}
                onChange={(e) => {
                  updateDraftFacilityTypeFilters(
                    'PrimaryCare',
                    e.target.checked
                  );
                }}
              >
                {strings.PrimaryCare}
              </Checkbox>
              <Checkbox
                name="UrgentCare"
                checked={draftFacilityTypeFilters?.includes('UrgentCare')}
                onChange={(e) => {
                  updateDraftFacilityTypeFilters(
                    'UrgentCare',
                    e.target.checked
                  );
                }}
              >
                {strings.UrgentCare}
              </Checkbox>
            </Fieldset>
          </StyledFormControl>
        </StyledFilterGroupsContainer>
      </StyledFilterSectionContainer>
      <Footer>
        <FloatingButton half onClick={applyFilter}>
          Apply
        </FloatingButton>
        <FloatingButton white half onClick={() => setFilterVisible(false)}>
          Cancel
        </FloatingButton>
      </Footer>
    </StyledFilterContainer>
  );
};

FilterView.propTypes = {};

FilterView.defaultProps = {};

export default FilterView;
