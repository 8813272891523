// Framework and third-party non-ui
import React from 'react';

// Helpers/utils/modules
import { isEmail } from 'utils/helpers';

// Component specific modules (Component-styled, etc.)
import { LocationButtonsContainer } from './LocationCard-styled';

// App components
import { IconButton } from '../baseComponents';
import DirectionsButton from '../DirectionsButton';

// Third-party components (buttons, icons, etc.)
import Skeleton from 'react-loading-skeleton';
import CalendarIcon from 'calcite-ui-icons-react/CalendarIcon';
import PhoneIcon from 'calcite-ui-icons-react/PhoneIcon';

// JSON

// CSS

const LocationButtons = ({
  facility,
  focalPoint,
  showDirections = true,
  vaccineTypeFilter,
}) => {
  // Some websites are email addresses... prepend mailto: if thats the case
  const attributes = facility?.attributes || [];

  let scheduleHref = attributes?.Website;

  if (scheduleHref && isEmail(scheduleHref)) {
    scheduleHref = `mailto:${scheduleHref}`;
  }
  const facilityName = attributes?.FacilityName || '';
  const facilityAddress = attributes?.Address || '';
  const ariaLabelSchedule =
    'Schedule appointment at ' + facilityName + ', ' + facilityAddress;
  const ariaLabelCall =
    'Call for appointment at ' + facilityName + ', ' + facilityAddress;

  return facility !== null ? (
    <LocationButtonsContainer
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {scheduleHref && (
        <IconButton
          icon={<CalendarIcon size={16} />}
          iconPosition="before"
          href={scheduleHref}
          target="_blank"
          aria-label={ariaLabelSchedule}
        >
          Schedule appointment
        </IconButton>
      )}
      {attributes?.Phone && !scheduleHref && (
        <IconButton
          href={`tel:${attributes.Phone}`}
          icon={<PhoneIcon size={16} />}
          fullWidth
          iconPosition="before"
          aria-label={ariaLabelCall}
        >
          Call for appointment
        </IconButton>
      )}
      {/* {attributes.Phone && (
        <IconButton
          iconPosition="before"
          small
          icon={<PhoneIcon size={16} />}
          href={`tel:${attributes.Phone}`}
        >
          Call
        </IconButton>
      )} */}
      {attributes?.Address && (
        <DirectionsButton
          facility={facility}
          focalPoint={focalPoint}
          iconOnly={false}
        />
      )}
    </LocationButtonsContainer>
  ) : (
    <LocationButtonsContainer>
      <Skeleton width={180} height={32} />
      <Skeleton width={120} height={32} style={{ marginLeft: '5px' }} />
    </LocationButtonsContainer>
  );
};

export default LocationButtons;
