// Framework and third-party non-ui
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import { StyledFilterSubNavContainer } from '../CovidFilterSubNav/CovidFilterSubNav-styled';

// App components
import { ToggleButton } from 'components/baseComponents';

// Third-party components (buttons, icons, etc.)

// JSON
import strings from 'strings/en.json';

// CSS
import AdaIcon from 'assets/wheelchair-move-medium-blue.svg';

const MonkFilterSubNav = ({ openFilter }) => {
  let showFilter = useRouteMatch({
    path: ['/locations/Mpox/', '/'],
    exact: true,
  });

  const { attributeFilters, updateAttributeFilters } = useContext(AppContext);

  const toggleHandler = (type, value) => {
    let result = value ? null : 'Yes';
    if (type === 'walkIn') {
      updateAttributeFilters({
        ...attributeFilters,
        Intake_WalkIn: result,
      });
    } else if (type === 'accessible') {
      updateAttributeFilters({
        ...attributeFilters,
        ADA_Compliant: result,
      });
    }
  };

  return (
    //<div></div>
    <StyledFilterSubNavContainer
      role="region"
      aria-label="Locations"
      showFilter={showFilter}
    >
      <ToggleButton
        active={attributeFilters.Intake_WalkIn ? true : false}
        onClick={() => toggleHandler('walkIn', attributeFilters.Intake_WalkIn)}
        aria-label="Filter locations with walk up availability"
        aria-pressed={attributeFilters.Intake_WalkIn ? true : false}
      >
        Walk-ups
      </ToggleButton>
      <ToggleButton
        invertSvg
        active={attributeFilters.ADA_Compliant ? true : false}
        onClick={() => {
          toggleHandler('accessible', attributeFilters.ADA_Compliant);
        }}
        aria-label="Filter accessible locations"
        aria-pressed={attributeFilters.ADA_Compliant ? true : false}
      >
        <img
          className="tooltip-label"
          src={AdaIcon}
          alt="Wheelchair access icon"
          title="This site has a physically accessible pathway to the vaccine administration"
          style={{ width: 12, minWidth: 12, height: 14 }}
        />
      </ToggleButton>
    </StyledFilterSubNavContainer>
  );
};

MonkFilterSubNav.propTypes = {
  openFilter: PropTypes.func,
};

MonkFilterSubNav.defaultProps = {};

export default MonkFilterSubNav;
