import styled from 'styled-components';

import { CalciteH1 } from 'calcite-react/Elements';

import { StyledSearchContainer } from '../EsriSearch/Search-styled';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem ${(props) => props.theme.global.padding};
  box-sizing: border-box;
  margin-inline-end: ${(props) => props.theme.global.margin};
  background: ${(props) => props.theme.palette.colors.primary};
  z-index: 4;

  ${StyledSearchContainer} {
    box-shadow: 0 0 0 1px rgba(120, 120, 120),
      rgba(0, 0, 0, 0.3) 0px 0px 16px 0px;

    .esri-search__suggestions-menu {
      width: 100%;
    }
  }
`;

export const StyledCalciteH1 = styled(CalciteH1)`
  font-size: 1.5rem;
  line-height: inherit;
  color: ${(props) => props.theme.palette.colors.white};
  margin-bottom: 0;
  margin-inline-start: 1.5rem;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const StyledBrandingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  h1 {
    margin: 0;
  }

  a {
    display: inline-block;
  }

  img {
    height: 28px;
  }

  @media screen and (max-width: 650px) {
    img {
      height: 22px;
    }
  }
`;

export const StyledLocatorContainer = styled.div`
  width: 70%;
  max-width: 24rem;

  @media screen and (max-width: 800px) {
    max-width: 350px;
  }

  input {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;
