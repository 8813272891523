// Framework and third-party non-ui
import React, { useContext } from 'react';
// Helpers/utils/modules

// Component specific modules (Component-styled, etc.)

// App components
import { IconButton } from '../baseComponents';
import { isMonkPoxSeason } from 'constants/map';
import { AppContext } from 'contexts/AppContext';

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const FaqButton = () => {
  // If we get a setter use that instead of the preferred href

  const { vaccineTypeFilter } = useContext(AppContext);

  const openLinkInNewTab = () => {
    switch (vaccineTypeFilter.VaccineType) {
      case 'Monkeypox': {
        window.open(
          'https://www.nyc.gov/site/doh/health/health-topics/mpox.page',
          '_blank'
        );
        break;
      }
      case 'Flu': {
        window.open(
          'https://www1.nyc.gov/site/doh/health/health-topics/flu-seasonal.page',
          '_blank'
        );
        break;
      }
      default: {
        window.open(
          'https://www1.nyc.gov/site/doh/covid/covid-19-vaccines.page',
          '_blank'
        );
        break;
      }
    }
  };

  return (
    <IconButton
      style={{ justifySelf: 'right' }}
      className="notranslate"
      content="notranslate"
      onClick={openLinkInNewTab}
    >
      More Info
    </IconButton>
  );
};

export default FaqButton;
