import styled, { css } from 'styled-components';

import { StyledMultiSelectButton } from 'calcite-react/MultiSelect/MultiSelect-styled';
import { CalciteH5, CalciteP } from 'calcite-react/Elements';

export const StyledFilterSubNavContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  background: ${(props) => props.theme.palette.colors.primary};
  padding: ${(props) => props.theme.global.paddingSmall}
    ${(props) => props.theme.global.padding};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.global.boxShadow};
  position: relative;
  z-index: 3 !important;

  ${(props) =>
    !props.showFilter &&
    css`
      visibility: hidden;
      height: 0;
      padding 0;
    `};

  button {
    margin-inline-end: ${(props) => props.theme.global.padding};
  }

  ${StyledMultiSelectButton} {
    margin: 0;
    padding-inline-start: 1rem;
    background-position: 95% center;
  }

  @media screen and (max-width: 650px) {
    & {
      justify-content: center;
    }

    button {
      font-size: 0.8rem;

      &:last-child {
        margin: 0;
      }
    }
  }
`;

export const StyledSectionHeader = styled.header`
  background: ${(props) => props.theme.palette.colors.white};
  text-align: left;
  width: 100%;
  font-weight: bold;
`;

export const FilterButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  height: 1.8rem;
  max-width: 100%;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.55rem;
  color: ${(props) => props.theme.palette.colors.primary};
  background-color: ${(props) => props.theme.palette.colors.white};
  border: 0;
  border-radius: 0;
  white-space: nowrap;
  svg {
    margin-inline-start: 0.25rem;
  }
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.palette.colors.offWhite};
  }
`;

export const PopperActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button:last-child {
    margin-inline-end: 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${(props) => props.theme.global.paddingSmall}
    ${(props) => props.theme.global.padding};
  z-index: 2;
`;

export const FilterSubContainer = styled.div`
  flex-direction: column;
`;

export const StyledCalciteH5 = styled(CalciteH5)`
  color: black;
  margin: 0;
  padding: 2vh 0 2vh 0;
`;

export const StyledCalciteP = styled(CalciteP)`
  margin: 0;
  font-size: 13px;
`;
