import styled, { css } from 'styled-components';

import Button from 'calcite-react/Button';

export const FloatingButton = styled(Button)`
  box-shadow: ${(props) => props.theme.global.boxShadow};

  ${(props) =>
    props.extraSmall &&
    css`
      padding-inline: 1.55rem;
      line-height: 1;
    `};
`;
