import styled, { css } from 'styled-components';

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0.6rem;
  box-sizing: border-box;
  background: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.boxShadow};

  ${(props) =>
    !props.noPosition &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
    `};
`;
