import { environments, webmaps, noticeServerTables } from 'constants/map';

const formatHref = ({ facility, focalPoint }) => {
  // https://developers.google.com/maps/documentation/urls/get-started#directions-action

  // const destinationParam = `${facility.attributes.FacilityName}+${[
  //   facility.attributes.Address,
  //   facility.attributes.Address2,
  //   facility.attributes.Borough,
  //   facility.attributes.ZipCode,
  // ].filter(Boolean)}`;

  //* NOTE: Removing FacilityName as Google Maps can't find a few of our
  //* facilities when we include the name
  //* https://dev.azure.com/Esri-Professional-Services/NYC%20Covid%20Vaccine%20Finder%20App/_workitems/edit/13110/
  const destinationParam = `${[
    facility.attributes.Address,
    facility.attributes.Address2,
    facility.attributes.Borough,
    facility.attributes.ZipCode,
  ].filter(Boolean)}`;

  const directionsUrl = new URL('https://www.google.com/maps/dir/');
  directionsUrl.searchParams.append('api', '1');
  directionsUrl.searchParams.append('destination', destinationParam);

  if (focalPoint?.attributes?.Address) {
    // href with both an origin param and a destination param
    directionsUrl.searchParams.append('origin', focalPoint.attributes.Address);
    return directionsUrl.href;
  } else {
    // href with only a destination param
    return directionsUrl.href;
  }
};

const isEmail = (link) => {
  return /\S+@\S+\.\S+/.test(link);
};

const setEnvironment = () => {
  const environment = environments[window.location.hostname];
  window.app_env = environment;
  if (window.location.hostname.startsWith('pr-')) window.app_env = 'dev';
  return environment;
};

const getWebmapId = () => {
  // Return appropriate webmap id, defaulting to prod
  return webmaps[window.app_env] || webmaps.prod;
};

const getNoticeServerTableUrl = () => {
  return noticeServerTables[window.app_env] || noticeServerTables.prod;
};

export {
  formatHref,
  isEmail,
  setEnvironment,
  getWebmapId,
  getNoticeServerTableUrl,
};
