import styled, { css } from 'styled-components';

import {
  StyledAccordion,
  StyledAccordionTitle,
  StyledAccordionContent,
} from 'calcite-react/Accordion/Accordion-styled';

import Button from 'calcite-react/Button';
import { CalciteA, CalciteH4, CalciteP } from 'calcite-react/Elements';

export const StyledVaccineSectionContainer = styled.div`
  margin: 0.5rem 0 0.1rem 0;
  background: ${(props) => props.theme.palette.white};
  border-radius: 6px;
  display: flex;
  padding-inline-start: 1rem;
`;

export const StyledVaccineName = styled(CalciteH4)`
  color: ${(props) => props.theme.palette.colors.primary};
  font-weight: bold;
  padding-inline-start: 0.5rem;
  font-size: 0.9rem;
  line-height: 2rem !important;
  margin: 0;
`;

export const StyledVaccineDetailContainer = styled.div`
  background: ${(props) => props.theme.palette.white};

  ${StyledAccordionTitle} {
    font-size: 0.8rem;
    font-weight: bold;
    padding-inline-start: 1rem;
    padding: 0;
    border: 0;
  }
  ${StyledAccordionContent} {
    padding: 0 0.775rem 0 0.775rem;
    padding-inline-start: 1.5rem;
  }
`;

export const AccordionTitleContainer = styled.div`
  padding: 0.5166666666666667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.palette.colors.offWhite};
  }
`;

export const SubnTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailSection = styled.div`
  margin: 0.5rem 0;
  padding-inline-start: 0.3rem;
  border-left: 0.2rem solid ${(props) => props.theme.palette.offWhite};
  ${CalciteP} {
    margin: 0;
    font-size: 0.8rem;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.colors.button};
  color: ${(props) => props.theme.palette.colors.white};
  padding: 0.6rem;
  :hover {
    background: ${(props) => props.theme.palette.colors.buttonHover};
    color: ${(props) => props.theme.palette.colors.white};
  }
`;
