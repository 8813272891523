import styled, { css } from 'styled-components';

import { CalciteH3, CalciteH4, CalciteP } from 'calcite-react/Elements';
import { TableRow } from 'calcite-react/Table';

export const StyledHoursRow = styled(TableRow)`
  td {
    font-size: 0.8rem;
    font-weight: 400;
    padding-top: 0;
    padding-bottom: 0;

    ${(props) =>
      props.isToday &&
      css`
        font-weight: 600;
      `};
  }
`;

export const StyledFacilityHoursListContainer = styled.div`
  margin: 0.5rem 0;
  background: ${(props) => props.theme.palette.white};
  border-radius: 6px;
`;

export const StyledHoursTableContainer = styled.div`
  margin: 0.5rem 0;
  padding-inline-start: 0.3rem;
  border-left: 0.2rem solid ${(props) => props.theme.palette.offWhite};
  ${CalciteP} {
    margin: 0;
    font-size: 0.8rem;
  }
`;

export const StyledServiceListContainer = styled.div``;

export const StyledViewMoreContainer = styled.div``;

export const StyledCalciteH3 = styled(CalciteH3)`
  display: inline-flex;
  font-size: 1rem;
  margin: 0;
  padding: 0.5rem 1rem;
  line-height: 1;
  color: ${(props) => props.theme.palette.colors.primary};

  svg {
    margin-inline-end: 0.25rem;
  }
`;

export const StyledFacilityHoursSectionContainer = styled.div`
  padding: 0.5rem 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledServiceName = styled(CalciteH4)`
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 400;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.darkGray};
  }

  svg {
    margin-inline-start: 0.75rem;
  }
`;

export const StyledFieldList = styled.div`
  font-size: 0.8rem;
  display: flex;
  color: ${(props) => props.theme.palette.darkGray};

  ${(props) =>
    props.expanded &&
    css`
      flex-direction: column;
    `};

  p {
    margin: 0;
    margin-inline-end: 1rem;
    font-weight: 400;

    ${(props) =>
      props.expanded &&
      css`
        padding-inline-start: 0.5rem;
      `};
  }
`;

export const StyledAdditionalInfo = styled(CalciteP)`
  font-size: 0.8rem;
  line-height: 1.25rem;
  margin: 0.75rem 0;
  padding-inline-start: 0.5rem;
  color: ${(props) => props.theme.palette.darkGray};
`;
