// React
import React from 'react';
import ReactDOM from 'react-dom';

// React Router
import { BrowserRouter, Route } from 'react-router-dom';

// Calcite React
import CalciteThemeProvider from 'calcite-react/CalciteThemeProvider';

// 3rd Party reporting
import reportWebVitals from './reportWebVitals';

// App-specific
import App from './App';
import { homepage } from '../package.json';
import { setEnvironment } from 'utils/helpers';
import GlobalStyle from './GlobalStyle.js';
import CustomTheme from './CustomTheme.js';

import config from '@arcgis/core/config.js';
import '@arcgis/core/assets/esri/css/main.css';

// Google Analytics
import ReactGA from 'react-ga4';

// Set app environment
setEnvironment();

// App runs at the root locally, but under /{homepage} in production
let basename;
process.env.NODE_ENV !== 'production' ? (basename = '') : (basename = homepage);

// JSAPI asset path configuration
config.assetsPath = `${basename}/assets`;

// Fix vh on mobile
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Google Analytics
let ga_id = 'G-3Z44K2V10C';
ReactGA.initialize(ga_id);
ReactGA.send('pageview');

ReactDOM.render(
  <React.StrictMode>
    <CalciteThemeProvider theme={CustomTheme}>
      <GlobalStyle />
      <BrowserRouter basename={basename}>
        <Route path="/" component={App} />
      </BrowserRouter>
    </CalciteThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
