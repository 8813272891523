// Framework and third-party non-ui
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import {
  StyledFacilityHoursListContainer,
  StyledHoursRow,
  StyledHoursTableContainer,
} from './LocationHoursSection-styled';

// App components

// Third-party components (buttons, icons, etc.)
import Table, { TableBody, TableCell } from 'calcite-react/Table';

// JSON
import strings from 'strings/en.json';

// CSS

const LocationHoursSection = ({ service }) => {
  const dayIndex = useMemo(() => {
    return new Date().getDay();
  }, []);

  // const hasValidHours = useMemo(() => {
  //   return !!days.find((dayStr) => {
  //     return service[`Hours_${dayStr}`] !== 'Closed - Closed';
  //   });
  // }, [service]);
  const checkHours = () => {
    return service.Hours_Sunday !== 'Call Site for Hours';
  };

  return checkHours() ? (
    <StyledFacilityHoursListContainer>
      <StyledHoursTableContainer id="hours_table_container">
        <Table plain justified>
          <TableBody>
            {/* {hasValidHours ? (
              <> */}
            <StyledHoursRow isToday={dayIndex === 0}>
              <TableCell>Sunday</TableCell>
              <TableCell>
                {service.Hours_Sunday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Sunday}
              </TableCell>
            </StyledHoursRow>
            <StyledHoursRow isToday={dayIndex === 1}>
              <TableCell>Monday</TableCell>
              <TableCell>
                {service.Hours_Monday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Monday}
              </TableCell>
            </StyledHoursRow>
            <StyledHoursRow isToday={dayIndex === 2}>
              <TableCell>Tuesday</TableCell>
              <TableCell>
                {service.Hours_Tuesday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Tuesday}
              </TableCell>
            </StyledHoursRow>
            <StyledHoursRow isToday={dayIndex === 3}>
              <TableCell>Wednesday</TableCell>
              <TableCell>
                {service.Hours_Wednesday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Wednesday}
              </TableCell>
            </StyledHoursRow>
            <StyledHoursRow isToday={dayIndex === 4}>
              <TableCell>Thursday</TableCell>
              <TableCell>
                {service.Hours_Thursday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Thursday}
              </TableCell>
            </StyledHoursRow>
            <StyledHoursRow isToday={dayIndex === 5}>
              <TableCell>Friday</TableCell>
              <TableCell>
                {service.Hours_Friday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Friday}
              </TableCell>
            </StyledHoursRow>
            <StyledHoursRow isToday={dayIndex === 6}>
              <TableCell>Saturday</TableCell>
              <TableCell>
                {service.Hours_Saturday === 'Closed - Closed'
                  ? strings.closed
                  : service.Hours_Saturday}
              </TableCell>
            </StyledHoursRow>
            {/* </>
            ) : (
              <StyledHoursRow>
                <TableCell>
                  Please contact provider for available hours
                </TableCell>
              </StyledHoursRow>
            )} */}
          </TableBody>
        </Table>
      </StyledHoursTableContainer>
    </StyledFacilityHoursListContainer>
  ) : null;
};

LocationHoursSection.propTypes = {
  service: PropTypes.object.isRequired,
};

LocationHoursSection.defaultProps = {};

export default LocationHoursSection;
