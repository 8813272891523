import React, { useState } from 'react';

// Component specific modules (Component-styled, etc.)
import { StyledCalciteP } from '../../pages/Home/Home-styled';
import {
  StyledHomeFAQContainer,
  StyledHomeFAQDropdownContainer,
  DisclaimerContainer,
} from './FaqDropdown-styled';
// Third-party components (buttons, icons, etc.)
import Accordion, {
  AccordionSection,
  AccordionTitle,
  AccordionContent,
} from 'calcite-react/Accordion';
import { CalciteA, CalciteUl, CalciteLi } from 'calcite-react/Elements';

const FaqDropdown = () => {
  const [activeSectionIndexes, setActiveSecionIndexes] = useState([]);

  function onAccordionChange(evt, index) {
    activeSectionIndexes.includes(index)
      ? setActiveSecionIndexes([
          activeSectionIndexes.filter((item) => index !== item),
        ])
      : setActiveSecionIndexes([...activeSectionIndexes, index]);
  }

  return (
    <StyledHomeFAQContainer aria-label="FAQ Container">
      <StyledHomeFAQDropdownContainer aria-label="FAQ Dropdown">
        <Accordion
          iconPosition="start"
          onAccordionChange={onAccordionChange}
          activeSectionIndexes={activeSectionIndexes}
        >
          <DisclaimerContainer>
            <StyledCalciteP>
              This FAQ is specifically for information about the COVID-19
              vaccine. For information about the seasonal flu vaccination,
              please visit{' '}
              <CalciteA href="https://nyc.gov/flu" target="_blank">
                nyc.gov/flu
              </CalciteA>
              .
            </StyledCalciteP>
          </DisclaimerContainer>
          <AccordionSection
            role="article"
            aria-label="What is the Vaccine Finder?"
          >
            <AccordionTitle contained={true}>
              What is the Vaccine Finder?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                The Vaccine Finder is a one-stop site for New Yorkers to find
                their nearest COVID-19 vaccination location. The Vaccine Finder
                can also provide New Yorkers with information to schedule
                vaccination appointments. You can search specifically for
                facilities that offer walk-up vaccinations (no appointment
                necessary) and for certain vaccine brands.
              </StyledCalciteP>
              <StyledCalciteP>
                To get started, type in an address, ZIP code, or click “near me”
                to find a list of nearby provider locations. You will also see
                links and phone numbers to schedule an appointment. Many sites
                do not require an appointment to get vaccinated.
              </StyledCalciteP>
              <StyledCalciteP noticeText>
                Each site manages its own schedules and appointments. This tool
                is intended to help make site information – including scheduling
                options – easily accessible to New Yorkers.
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="Who can get vaccinated right now?"
          >
            <AccordionTitle contained={true}>
              Who can get vaccinated right now?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                Everyone 5 years of age or older is eligible for the COVID-19
                vaccine. People who are 5 to 17 years old are only eligible for
                the Pfizer vaccine.
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="How can I get vaccinated at home?"
          >
            <AccordionTitle contained={true}>
              How can I get vaccinated at home?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                All New Yorkers 5 and older can be vaccinated at home. You can
                sign up for in-home vaccination at{' '}
                <CalciteA
                  href="https://forms.cityofnewyork.us/f/home"
                  target="_blank"
                >
                  https://forms.cityofnewyork.us/f/home
                </CalciteA>{' '}
                (not accessible on Internet Explorer) or by calling 877-VAX-4NYC
                (877-829-4692). You will then be contacted to schedule your
                in-home vaccination. You can choose to receive the two-dose
                Pfizer-BioNTech or Moderna vaccine or the one-dose Johnson &
                Johnson/Janssen vaccine. Multiple people in the same household
                can be vaccinated during a single visit.
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="How do I get a reasonable accommodation to schedule an appointment or travel to a vaccination site?"
          >
            <AccordionTitle contained={true}>
              How do I get a reasonable accommodation to schedule an appointment
              or travel to a vaccination site?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                People with disabilities can get help making a vaccination
                appointment at an accessible site <strong>or</strong> traveling
                to their appointment and getting their shot. This kind of help
                is called a reasonable accommodation.
              </StyledCalciteP>
              <StyledCalciteP>
                You can get a reasonable accommodation if you have difficulty
                with:
              </StyledCalciteP>
              <StyledCalciteP>
                <CalciteUl>
                  <CalciteLi>Seeing or hearing</CalciteLi>
                  <CalciteLi>Thinking or concentrating</CalciteLi>
                  <CalciteLi>Speaking</CalciteLi>
                  <CalciteLi>Using your arms</CalciteLi>
                  <CalciteLi>Taking care of daily chores</CalciteLi>
                  <CalciteLi>
                    Coping with feelings of sadness or anxiety
                  </CalciteLi>
                  <CalciteLi>Getting around or climbing stairs</CalciteLi>
                </CalciteUl>
              </StyledCalciteP>
              <StyledCalciteP>
                You can request a reasonable accommodation at a City-run vaccine
                site through the City's{' '}
                <CalciteA
                  href="https://vax4nyc.nyc.gov/patient/s/"
                  target="_blank"
                >
                  online appointment scheduler
                </CalciteA>{' '}
                or by calling 877-VAX-4NYC (877-829-4692). You can also ask for
                a reasonable accommodation from staff at a City-run vaccination
                site.
              </StyledCalciteP>
              <StyledCalciteP>
                You can also schedule an in-home vaccination (see details
                above).
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="What should I do before I go to a vaccination site?"
          >
            <AccordionTitle contained={true}>
              What should I do before I go to a vaccination site?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                Make an appointment if the site requires one.
              </StyledCalciteP>
              <StyledCalciteP>
                Wear a face covering to your appointment. You will not be
                admitted to the site without one.
              </StyledCalciteP>
              <StyledCalciteP>
                You will also need to complete the{' '}
                <CalciteA
                  href="https://forms.ny.gov/s3/vaccine"
                  target="_blank"
                >
                  NYS COVID-19 Vaccine Form
                </CalciteA>{' '}
                before your appointment. The person administering the vaccine is
                required to check that you completed the form before you can get
                vaccinated.
              </StyledCalciteP>
              <StyledCalciteP>
                Reschedule your appointment if you are not feeling well on the
                day of your appointment.
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="What should I bring to my appointment to prove I am eligible for a
            vaccine?"
          >
            <AccordionTitle contained={true}>
              What should I bring to my appointment to prove I am eligible for a
              vaccine?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                You must bring proof of age to your vaccination.
              </StyledCalciteP>
              <StyledCalciteP>
                You will <strong>not</strong> need to provide proof of
                immigration status or a social security number to get
                vaccinated. ID is only required for proof of age.
              </StyledCalciteP>
              <StyledCalciteP>
                When you receive the vaccine, your privacy will be protected.
                There are strict laws in place to ensure confidentiality of your
                personal information.
              </StyledCalciteP>
              <StyledCalciteP>
                Proof you are 5 or older may include:
              </StyledCalciteP>
              <StyledCalciteP>
                <CalciteUl>
                  <CalciteLi>Driver's license or non-driver ID</CalciteLi>
                  <CalciteLi>IDNYC</CalciteLi>
                  <CalciteLi>
                    State or government-issued birth certificate
                  </CalciteLi>
                  <CalciteLi>
                    Current U.S. passport or valid foreign passport
                  </CalciteLi>
                  <CalciteLi>Permanent resident card</CalciteLi>
                  <CalciteLi>
                    Certificate of Naturalization or Citizenship
                  </CalciteLi>
                  <CalciteLi>Life insurance policy with birthdate</CalciteLi>
                  <CalciteLi>Marriage certificate with birthdate</CalciteLi>
                  <CalciteLi>
                    For people younger than 18, a parent or guardian can
                    accompany them to the vaccination site to attest to their
                    age
                  </CalciteLi>
                </CalciteUl>
              </StyledCalciteP>
              <StyledCalciteP>
                A parent or guardian must provide consent for their child to be
                vaccinated in person or by phone. Some providers, including all
                City-run sites, will accept proof of consent in writing.
                However, in-person or phone consent is preferred.
              </StyledCalciteP>
              <StyledCalciteP>
                Anyone 5 to 15 years of age must be accompanied to the
                vaccination site by a parent or guardian, or by another adult
                caregiver designated by the parent or guardian.
              </StyledCalciteP>
              <StyledCalciteP>
                The child must also have a completed{' '}
                <CalciteA
                  href="https://forms.ny.gov/s3/vaccine"
                  target="_blank"
                >
                  NYS COVID-19 Immunization Screening and Consent Form
                </CalciteA>
                .
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="How can I get to my vaccination site?"
          >
            <AccordionTitle contained={true}>
              How can I get to my vaccination site?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                If you are 65 or older, cannot use public or private
                transportation and don't have anyone to take you to your
                vaccination appointment, other transportation options are
                available.
              </StyledCalciteP>
              <StyledCalciteP>
                To request free transportation to and from a vaccination
                appointment for city residents 65 and older, as well as for
                those with disabilities who have no other way to get to a
                vaccination site, call the NYC Vaccine Hotline 877-VAX4NYC
                (877-829-4692).
              </StyledCalciteP>
              <StyledCalciteP>
                If you are younger than 18, you must have your parent or
                guardian call to schedule the trip.
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="When do I get my second dose of the Pfizer or Moderna vaccine?"
          >
            <AccordionTitle contained={true}>
              When do I get my second dose of the Pfizer or Moderna vaccine?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                The Pfizer and Moderna vaccines require two shots. You should
                get your second Pfizer shot 21 days after the first one, or your
                second Moderna shot 28 days after the first one. If you are
                unable to get your second shot on that schedule, get it as soon
                as possible after that date. You should get the second dose no
                matter how much time has passed.
              </StyledCalciteP>
              <StyledCalciteP>
                The Johnson & Johnson/Janssen vaccine only requires one dose.
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="Should I get a third dose or booster shot?"
          >
            <AccordionTitle contained={true}>
              Should I get a third dose or booster shot?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                Vaccine booster shots are now available for many New Yorkers.
                They are intended to help people boost their immunity from the
                initial vaccination series.
              </StyledCalciteP>
              <StyledCalciteP>
                Some people who got the Pfizer or Moderna COVID-19 vaccine at
                least six months ago and who are at increased risk of COVID-19
                exposure or severe COVID-19 illness can get a booster shot.
                Everyone who got the Johnson & Johnson vaccine at least two
                months ago should get a booster shot.
              </StyledCalciteP>
              <StyledCalciteP>
                People have the choice of getting any of the three authorized
                vaccines for their booster shot (Pfizer, Moderna, Johnson &
                Johnson) and do not need to get the same vaccine as their
                initial series.
              </StyledCalciteP>
              <StyledCalciteP>
                <CalciteA
                  href="https://www1.nyc.gov/site/doh/covid/covid-19-vaccines.page"
                  target="_blank"
                >
                  Learn if you are eligible for a booster shot
                </CalciteA>
                .
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <AccordionSection
            role="article"
            aria-label="Why should I get vaccinated?"
          >
            <AccordionTitle contained={true}>
              Why should I get vaccinated?
            </AccordionTitle>
            <AccordionContent>
              <StyledCalciteP>
                Vaccination is the best way to protect yourself from severe
                COVID-19 illness, hospitalization and death. Vaccination also
                helps protect the people around you, since you are much less
                likely to get and spread COVID-19.
              </StyledCalciteP>
              <StyledCalciteP>
                <CalciteA
                  href="https://www1.nyc.gov/site/doh/covid/covid-19-vaccine-facts.page"
                  target="_blank"
                >
                  Learn more about the safety and effectiveness of the COVID-19
                  vaccines.
                </CalciteA>
                .
              </StyledCalciteP>
            </AccordionContent>
          </AccordionSection>
          <DisclaimerContainer>
            <StyledCalciteP>
              This application does not support use with Internet Explorer. When
              utilizing this application we suggest using a different browser.
            </StyledCalciteP>
          </DisclaimerContainer>
        </Accordion>
      </StyledHomeFAQDropdownContainer>
    </StyledHomeFAQContainer>
  );
};

export default FaqDropdown;
