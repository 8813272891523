import styled, { css } from 'styled-components';
import { FloatingButton } from '../../components/baseComponents';
import { unitCalc } from 'calcite-react/utils/helpers';

import {
  CalciteH1,
  CalciteH2,
  CalciteH3,
  CalciteP,
} from 'calcite-react/Elements';
import Alert, { AlertMessage } from 'calcite-react/Alert';
import { StyledCard, StyledCardContent } from 'calcite-react/Card/Card-styled';
import { StyledButton } from 'calcite-react/Button/Button-styled';
import { StyledLocationCardContainer } from 'components/LocationCard/LocationCard-styled';
import { StyledFloatingButton } from 'components/MapListToggle/MapListToggle-styled';

import { StyledFilterSubNavContainer } from 'components/CovidFilterSubNav/CovidFilterSubNav-styled';
import ListItemTitle from 'calcite-react/List/ListItemTitle';

export const StyledListContainer = styled.section`
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  @media screen and (max-width: 650px) {
    padding-bottom: 65px;
  }

  ${StyledLocationCardContainer} {
    margin-bottom: 0.25rem;
    box-sizing: border-box;
  }

  ${StyledFloatingButton} {
    display: none;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 650px) {
      display: block;
    }
  }
`;

export const StyledHomeContainer = styled.div`
  background: ${(props) => props.theme.palette.offWhite};
`;

export const StyledHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.palette.colors.white};
  text-align: center;

  ${StyledFilterSubNavContainer} {
    justify-content: center;
    box-shadow: none;
    z-index: 0;

    @media screen and (max-width: 650px) {
      justify-content: space-between;
    }
  }
`;

export const StyledHeroBrandingContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.4rem;
  margin-bottom: 0.8rem;
  @media screen and (max-width: 650px) {
    margin: 1rem;
    margin-bottom: 0.6rem;
  }

  img {
    height: ${(props) => unitCalc(props.theme.baseline, 2, '*')};
    visibility: hidden;
    @media screen and (max-width: 650px) {
      visibility: visible;
      height: ${(props) => unitCalc(props.theme.baseline, 1.5, '*')};
    }
  }
`;

export const StyledCalciteH1 = styled(CalciteH1)`
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.colors.primary};
  margin: 0;
  margin-top: ${(props) => props.theme.global.margin};
  line-height: inherit;
  margin-top: 0.4rem;
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
  }
`;

export const StyledHeroCalciteH2 = styled(CalciteH2)`
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.colors.white};
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${(props) => props.theme.global.margin};
  line-height: inherit;
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
  }
`;

export const StyledCalciteH2 = styled(CalciteH2)`
  width: 100%;
  max-width: 600px;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 1rem;
  color: ${(props) => props.theme.palette.colors.primary};
  line-height: 1.5rem;
`;

export const StyledCalciteH3 = styled(CalciteH3)`
  font-size: 1.1rem;
  font-weight: 400;
  margin: 1rem;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.palette.colors.primary};
  line-height: 1.5rem;

  ${(props) =>
    props.contained &&
    css`
      max-width: 600px;
    `};
`;

export const StyledCalciteP = styled(CalciteP)`
  max-width: 600px;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0.5rem 1rem;
  color: ${(props) => props.theme.palette.colors.black};

  ${(props) =>
    props.noticeText &&
    css`
      color: ${(props) => props.theme.palette.colors.accent1};
      font-weight: bold;
    `};

  ${(props) =>
    props.noticeTextBanner &&
    css`
      margin: ${(props) => props.theme.global.margin} 0 0;
      color: ${(props) => props.theme.palette.colors.accent2};
    `};
`;

export const StyledCalciteListItemTitle = styled(ListItemTitle)`
  font-size: inherit;
`;

export const StyledHeroLocatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: ${(props) => props.theme.palette.colors.white};
  background: ${(props) => props.theme.palette.colors.primary};
  padding: ${(props) => unitCalc(props.theme.baseline, 1.5, '/')};
  width: 100%;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.global.boxShadow};

  input {
    flex: 1;
    border-radius: 0;
    border: none;
    margin: 0;
  }

  ${StyledButton}, a {
    flex: 1;
    max-width: 312px;
  }

  @media screen and (max-width: 650px) {
    input {
      flex: auto;
      max-width: 100%;
    }
  }
`;

export const StyledLocatorLabel = styled(CalciteH2)`
  width: 100%;
  margin: 0;
  font-size: 1.25rem;

  @media screen and (max-width: 650px) {
    flex: 1 0 auto;
    text-align: center;
  }
`;

export const StyledFloatingLocateButton = styled(FloatingButton)`
  border-radius: 0;
  box-shadow: inherit;
`;

export const SearchWrapper = styled.div`
  box-shadow: ${(props) => props.theme.global.boxShadow};
  display: flex;
  svg {
    margin: 0 !important;
  }
  @media screen and (max-width: 650px) {
    width: 80vw;
    button {
      padding: 0.32rem 0.9rem;
    }
  }
  @media screen and (min-width: 651px) {
    width: 25rem;
  }
`;

export const StyledHomeFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledFAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  ${StyledCard} {
    max-width: 800px;
    text-align: start;
    border-radius: 0;
    margin: 0.3rem 0 0 0;
    box-shadow: inherit;
    border-top: 0;

    &:last-child {
      margin-bottom: 5rem;
    }

    ${StyledCardContent} {
      padding: 0;
    }

    h4 {
      margin: 0;
    }
  }
`;

// HOME ALERT BANNER (OUTER ALERT CONTAINER)
export const StyledHomeAlert = styled(Alert)`
  border-radius: 0rem;
  margin-bottom: 1rem;
  background-color: #bf4b0c;
  color: #fff !important;
  font-weight: bold;
  width: 100%;
  text-align: left;
  z-index: auto;
  border: none;
`;

// HOME ALERT BANNER (INNER MESSAGE CONTAINER)
export const StyledHomeAlertMessage = styled(AlertMessage)`
  max-width: 600px;
  margin: 0 auto;
  white-space: pre-wrap;
`;
