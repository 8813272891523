import styled, { css } from 'styled-components';

import MultiSelect from 'calcite-react/MultiSelect';
import { StyledSideNav } from 'calcite-react/SideNav/SideNav-styled';

export const StyledSelect = styled(MultiSelect)`
  margin: 0;
  color: ${(props) => props.theme.palette.colors.button};
  background-color: ${(props) => props.theme.palette.colors.buttonSelected};
  border-color: ${(props) => props.theme.palette.colors.button};

  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.1 6L7.99999 11.1L2.89999 6H13.1Z' fill='%231771B7'/%3E%3C/svg%3E%0A");
  background-position: 95% 50%;

  ${(props) =>
    props.small &&
    css`
      height: 28px;
    `};

  + ${StyledSideNav} {
    border-radius: 0;
  }
`;
