// Framework and third-party non-ui
import React from 'react';
import PropTypes from 'prop-types';

// Context & hooks

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import {
  StyledLocationHeaderContainer,
  StyledTitleContainer,
  StyledTitleWrapper,
  StyledShortcutContainer,
  StyledLocationContactContainer,
  StyledCalciteH2,
  StyledTypeProximityContainer,
  StyledProximityLabel,
  StyledCalciteP,
  Highlight,
  StyledParagraph,
  StyledParagraphSecondary,
  StyledTooltip,
} from './LocationHeader-styled';

// App components
import LocationAttribute from '../LocationAttribute';
import { StyledFieldValuesContainer } from '../LocationDetailSection/LocationDetailSection-styled';

// Third-party components (buttons, icons, etc.)
import Skeleton from 'react-loading-skeleton';
import Button from 'calcite-react/Button';
import { CalciteP, CalciteA } from 'calcite-react/Elements';
import Label from 'calcite-react/Label';
import XIcon from 'calcite-ui-icons-react/XIcon';
import PhoneIcon from 'calcite-ui-icons-react/PhoneIcon';
import PinIcon from 'calcite-ui-icons-react/PinIcon';
import CheckIcon from 'calcite-ui-icons-react/CheckCircleIcon';

import AdaIcon from 'assets/wheelchair-move-medium-blue.svg';
import AdaIconPng from 'assets/wheelchair-move-medium-blue.png';

// JSON
import strings from 'strings/en.json';

// CSS

const LocationHeader = ({
  facility,
  showHours,
  onClose,
  isPopup,
  selectable,
  vaccineTypeFilter,
  onFacilitySelected = () => {},
}) => {
  const covidType = vaccineTypeFilter?.VaccineType === 'COVID';

  const monkType = vaccineTypeFilter?.VaccineType === 'Monkeypox';

  const attributes = facility?.attributes || [];

  if (
    attributes.AdditionalInfo?.includes('Phone') &&
    attributes.AdditionalInfo?.includes('ext') &&
    !monkType
  ) {
    let number = attributes.AdditionalInfo?.split('ext ');
    let cleanedNumber = ('' + number[0]).replace(/\D/g, '');
    var match = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    let finalNumber =
      '(' + match[1] + ') ' + match[2] + '-' + match[3] + ',' + number[1];
    attributes.Phone = finalNumber;
    attributes.AdditionalInfo = '';
  }

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else if (phoneNumberString.includes(',')) {
      return phoneNumberString;
    }
    return null;
  };

  const getTodaysHours = () => {
    const dayOfWeek = new Date().getDay();
    const mapDayOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    // Are we going to be able to parce today's hours from the rest of the week?
    return attributes[mapDayOfWeek[dayOfWeek]];
  };
  let proximityVal = '< 0.1';
  if (facility?.proximity && facility.proximity >= 0.1) {
    proximityVal = facility.proximity.toFixed(1);

    if (facility.proximity > 9.9) {
      proximityVal = Math.round(facility.proximity);
    }
  }

  const showRestrictions = facility?.services[0]?.Vaccine_Restrictions;
  const showWalkUpInfo = facility?.services[0]?.Intake_WalkIn === 'Yes';
  const showWalkUpMonkInfo = facility?.attributes?.Intake_WalkIn === 'Yes';
  const showAddionalInfoMonk =
    facility?.attributes?.AdditionalInfo &&
    facility?.attributes?.AdditionalInfo !== null;
  const showApptPreferred =
    facility?.services[0]?.Intake_ApptPreferred ===
    strings.Intake_ApptPreferred;
  const headerTitle = attributes?.FacilityName || null;

  const headerClicked = (event) => {
    onFacilitySelected();
    event.preventDefault();
  };

  const showVaccinesOffered = () => {
    return (
      <StyledParagraph noWrap>
        <CheckIcon size={14} />
        <span>
          {facility.services.length === 1
            ? 'Vaccine offered:'
            : 'Vaccines offered:'}
        </span>
        <StyledFieldValuesContainer>
          {facility.services.length > 0
            ? facility.services.map((vaccine) => {
                if (
                  vaccine.ServiceType.includes(vaccineTypeFilter.VaccineType)
                ) {
                  return (
                    <LocationAttribute
                      vaccineTypeFilter={vaccineTypeFilter}
                      field={vaccine.ServiceType}
                      key={vaccine.OBJECTID}
                    />
                  );
                } else return null;
              })
            : 'None'}
        </StyledFieldValuesContainer>
      </StyledParagraph>
    );
  };

  return (
    <StyledLocationHeaderContainer isPopup={isPopup}>
      <StyledTitleContainer isPopup={isPopup}>
        <StyledTitleWrapper isPopup={isPopup}>
          <StyledCalciteH2 isPopup={isPopup} onClick={onFacilitySelected}>
            {selectable ? (
              <CalciteA href="/" onClick={(event) => headerClicked(event)}>
                {headerTitle || <Skeleton width={300} height={24} />}
              </CalciteA>
            ) : (
              <>{headerTitle || <Skeleton width={300} height={24} />}</>
            )}{' '}
            {attributes?.ADA_Compliant === 'Yes' && (
              <img
                className="tooltip-label"
                src={AdaIconPng}
                alt="Wheelchair access icon"
                title="This site has a physically accessible pathway to the vaccine administration"
                style={{ width: 12, height: 14 }}
              />
            )}
          </StyledCalciteH2>
          <StyledTypeProximityContainer aria-label="Facility Type Information">
            {(attributes?.FacilityType && (monkType || covidType) && (
              <Label aria-label="Facility Type">
                {attributes.FacilityType}{' '}
                {attributes.ADA_Compliant === 'Yes' && isPopup && (
                  <StyledTooltip
                    label={(props) => (
                      <span
                        {...props.labelAttributes}
                        className="tooltip-label"
                      >
                        <img
                          src={AdaIcon}
                          alt=""
                          style={{ width: 14, height: 14 }}
                          aria-label="There is a physically accessible pathway to the vaccine
                          administration"
                        />
                      </span>
                    )}
                    overlay={(props) => (
                      <span
                        {...props.overlayAttributes}
                        className={
                          props.isHidden
                            ? 'tooltip__overlay tooltip__overlay--hidden'
                            : 'tooltip__overlay tooltip__overlay--contained'
                        }
                      >
                        <StyledCalciteP
                          style={{
                            whiteSpace: 'pre-wrap',
                            marginBottom: '0px',
                          }}
                        >
                          There is a physically accessible pathway to the
                          vaccine administration
                        </StyledCalciteP>
                      </span>
                    )}
                  />
                )}
              </Label>
            )) || <Label> {'  '} </Label>}
            {facility?.proximity && (
              <StyledProximityLabel>{proximityVal} mi</StyledProximityLabel>
            )}
          </StyledTypeProximityContainer>
        </StyledTitleWrapper>
        {onClose && (
          <StyledShortcutContainer>
            <Button
              iconButton
              icon={<XIcon />}
              onClick={onClose}
              aria-label="close"
            />
          </StyledShortcutContainer>
        )}
      </StyledTitleContainer>
      {facility !== null ? (
        <StyledLocationContactContainer>
          <StyledCalciteP>
            <PinIcon size={14} />
            {attributes.Address}
            {attributes.Address2 && `, ${attributes.Address2}`}
            {attributes.Borough && `, ${attributes.Borough}`}
            {attributes.ZipCode && `, ${attributes.ZipCode}`}
          </StyledCalciteP>
          {attributes?.Phone && (
            <StyledCalciteP>
              <PhoneIcon size={12} />
              <CalciteA
                href={`tel:${attributes.Phone}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {formatPhoneNumber(attributes.Phone)}
              </CalciteA>
            </StyledCalciteP>
          )}
          {covidType && showVaccinesOffered()}
          {showRestrictions && covidType && (
            <StyledParagraph>
              <span>{facility.services[0]?.Vaccine_Restrictions}</span>
            </StyledParagraph>
          )}
          {showApptPreferred && covidType && (
            <StyledParagraph>
              <span>
                Appointments are encouraged; limited walk-ins accepted
              </span>
            </StyledParagraph>
          )}
          {/* Text for walk ins welcome to ny residents */}
          {showWalkUpInfo && covidType && (
            <StyledParagraph>
              <span>
                Walk-up vaccinations available to all eligible New Yorkers
              </span>
            </StyledParagraph>
          )}
          {attributes?.ADA_Compliant === 'No' && (
            <StyledParagraphSecondary>
              <span>
                Contact this location to find out if it is physically accessible
                to people with disabilities
              </span>
            </StyledParagraphSecondary>
          )}
          {showHours && <CalciteP>{getTodaysHours()}</CalciteP>}
          {showAddionalInfoMonk && monkType && (
            <StyledParagraph>
              <span>{facility.attributes.AdditionalInfo}</span>
            </StyledParagraph>
          )}

          {/* removing appointment availability functionality but it's possible that it will be reinstated - Oct 2022 */}

          {/* {attributes?.AppointmentAvailability === 'Available'
            ? monkType && (
                <Highlight isPositive={true}>APPOINTMENTS AVAILABLE!</Highlight>
              )
            : monkType &&
              attributes?.AppointmentAvailability === 'NotAvailable' &&
              !showWalkUpMonkInfo && (
                <Highlight isNegative={true}>
                  NO APPOINTMENTS AVAILABLE
                </Highlight> 
              )}
          {monkType &&
            attributes?.AppointmentAvailability === 'NotAvailable' &&
            showWalkUpMonkInfo && (
              <Highlight>CHECK FOR AVAILABLE APPOINTMENTS</Highlight>
            )}
          {(!attributes?.AppointmentAvailability ||
            attributes?.AppointmentAvailability === 'Unknown' ||
            attributes?.AppointmentAvailability === '' ||
            attributes?.AppointmentAvailability === undefined) &&
            monkType && <Highlight>CHECK FOR AVAILABLE APPOINTMENTS</Highlight>} */}
        </StyledLocationContactContainer>
      ) : (
        <div style={{ width: '210px' }}>
          <Skeleton width={200} count={3} />
        </div>
      )}
    </StyledLocationHeaderContainer>
  );
};

LocationHeader.propTypes = {
  facility: PropTypes.object,
  showShortcuts: PropTypes.bool,
  showHours: PropTypes.bool,
  onClose: PropTypes.func,
};

LocationHeader.defaultProps = {};

export default LocationHeader;
