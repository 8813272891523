// Framework and third-party non-ui
import React from 'react';
import PropTypes from 'prop-types';

// Helpers/utils/modules
import { formatHref } from 'utils/helpers';

// Component specific modules (Component-styled, etc.)

// App components
import { IconButton } from '../baseComponents';

// Third-party components (buttons, icons, etc.)
import RoadSignIcon from 'calcite-ui-icons-react/RoadSignIcon';

// JSON

// CSS

const DirectionsButton = ({
  facility,
  focalPoint,
  setPopupDirectionsRequest,
  iconOnly,
}) => {
  // If we get a setter use that instead of the preferred href
  const clickAction = {};
  if (setPopupDirectionsRequest) {
    clickAction.onClick = () => setPopupDirectionsRequest(facility);
  } else {
    clickAction.href = formatHref({ facility, focalPoint });
    clickAction.target = '_blank';
  }
  const attributes = facility?.attributes || [];
  const facilityName = attributes?.FacilityName || '';
  const facilityAddress = attributes?.Address || '';
  const ariaLabel = 'Direction to ' + facilityName + ', ' + facilityAddress;

  return iconOnly ? (
    <IconButton {...clickAction} aria-label={ariaLabel}>
      <RoadSignIcon size={16} />
    </IconButton>
  ) : (
    <IconButton
      {...clickAction}
      icon={<RoadSignIcon size={16} />}
      iconPosition="before"
      fullWidth
      aria-label={ariaLabel}
    >
      Directions
    </IconButton>
  );
};

DirectionsButton.propTypes = {
  facility: PropTypes.object.isRequired,
  focalPoint: PropTypes.object,
  iconOnly: PropTypes.bool,
  setPopupDirectionsRequest: PropTypes.func,
};

DirectionsButton.defaultProps = {
  iconOnly: true,
};

export default DirectionsButton;
