import { useEffect } from 'react';

export function useWatches(obj, names, callback) {
  useEffect(() => {
    if (!obj) {
      return;
    }
    const handles = names.map((name) => obj.watch(name, callback));
    return function removeHandles() {
      handles.forEach((handle) => {
        handle.remove();
      });
    };
  }, [obj, names, callback]);
}

export function useWatch(obj, name, callback) {
  useWatches(obj, [name], callback);
}
