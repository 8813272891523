// Framework and third-party non-ui
import React, { useContext } from 'react';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

//React router link componenet
import { useHistory } from 'react-router-dom';

// Component specific modules (Component-styled, etc.)
import {
  ToggleContainer,
  StyledButtonGroup,
  StyledButton,
} from './VaccineTypeToggle-styled';

// App components
import { closePopUp } from 'utils/arcgis';

// Third-party components (buttons, icons, etc.)

// JSON
import strings from 'strings/en.json';

//import Label from 'calcite-react/Label/Label';
//import constants
import { isFluSeason, isMonkPoxSeason } from 'constants/map';
// CSS
const buttonStyle = {
  background: '#0a5796',
};

let vaccineTypes = ['COVID'];

if (isFluSeason) {
  vaccineTypes.push('Flu');
}

if (isMonkPoxSeason) {
  vaccineTypes.push('Monkeypox');
}

const VaccineTypeToggle = () => {
  const {
    updateVaccineTypeFilter,
    updateAttributeFilters,
    vaccineTypeFilter,
    mapView,
  } = useContext(AppContext);
  const history = useHistory();
  const applyFilter = (vaccine) => {
    updateVaccineTypeFilter({
      VaccineType: vaccine,
    });
    updateAttributeFilters({
      ServiceType: [],
      ADA_Compliant: null,
      Intake_WalkIn: null,
      Payment_LowNoCost: '',
      Payment_Medicaid: '',
      Payment_LowNoCost: '',
    });
  };

  const toggleHandler = (vaccine) => {
    if (vaccine !== vaccineTypeFilter.VaccineType) applyFilter(vaccine);
    closePopUp(mapView);
  };

  return (
    <ToggleContainer id="vaccine-type-container">
      <StyledButtonGroup role="tablist" isToggle>
        {vaccineTypes.map((vaccine, i) => (
          <StyledButton
            role="tab"
            tabindex={i}
            key={i}
            // id, clear, active, and style props are for conditional button styling
            id={
              vaccineTypeFilter.VaccineType === vaccine
                ? 'inactive-button'
                : 'active-button'
            }
            clear={vaccineTypeFilter.VaccineType !== vaccine}
            active={vaccineTypeFilter.VaccineType === vaccine ? false : true}
            style={
              vaccineTypeFilter.VaccineType === vaccine ? buttonStyle : null
            }
            // aria-selected prop provides improved accessibility
            aria-selected={
              vaccineTypeFilter.VaccineType === vaccine ? 'true' : 'false'
            }
            aria-label={vaccine}
            onClick={() => {
              toggleHandler(vaccine);
            }}
          >
            {strings[vaccine]}
          </StyledButton>
        ))}
      </StyledButtonGroup>
    </ToggleContainer>
  );
};

export default VaccineTypeToggle;
