import styled from 'styled-components';

import { StyledLocationCardContainer } from 'components/LocationCard/LocationCard-styled';
import { StyledFloatingButton } from 'components/MapListToggle/MapListToggle-styled';
import { CalciteP } from 'calcite-react/Elements';

export const StyledListContainer = styled.section`
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;

  @media screen and (max-width: 650px) {
    padding-bottom: 65px;
  }

  ${StyledLocationCardContainer} {
    margin-bottom: 0.25rem;
  }

  ${StyledFloatingButton} {
    display: none;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 650px) {
      display: block;
    }
  }
`;

export const MessageContainer = styled.div`
  padding: 0.25rem 0.25rem;
  background: ${(props) => props.theme.palette.white};
  margin: 0.5rem 0 0.25rem 0;
`;

export const StyledCalciteP = styled(CalciteP)`
  max-width: 600px;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0.5rem 1rem;
  color: ${(props) => props.theme.palette.colors.black};
`;
