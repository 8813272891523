import styled, { css } from 'styled-components';
import { StyledFooter } from 'components/Footer/Footer-styled';

export const StyledLocationFooter = styled(StyledFooter)`
  ${(props) =>
    props.hideShadow &&
    css`
      box-shadow: none;
    `};

  ${(props) =>
    props.isPopup &&
    css`
      position: relative;
      padding: 0;
    `};

  > * {
    margin-inline-end: 0.5rem;

    &:last-child {
      margin-inline-end: 0;
    }
  }

  a,
  a:hover {
    color: ${(props) => props.theme.palette.white};
  }
`;
