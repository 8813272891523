import styled from 'styled-components';

import { CalciteOl } from 'calcite-react/Elements';

export const StyledA11yLegendContainer = styled.div`
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.palette.white};
  margin: 0.5rem 0 0.25rem 0;
`;

export const StyledFieldValuesContainer = styled(CalciteOl)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  font-size: 0.8rem;
  line-height: 1rem;

  li {
    display: inline-flex;
    align-items: center;
    margin: 0 -1rem;
    margin-inline-end: 0;

    img {
      margin-inline-end: 0.5rem;
    }
  }
`;
