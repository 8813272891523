// Framework and third-party non-ui
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledFilterSubNavContainer,
  FilterButton,
  PopperActions,
  StyledCalciteP,
  StyledSectionHeader,
} from './CovidFilterSubNav-styled';

// App components
import { ToggleButton } from 'components/baseComponents';
import InsuranceFilter from 'components/FluFilterSubNav/InsuranceFilter';

// Third-party components (buttons, icons, etc.)
import { usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'calcite-react/Button';
import CaretDownIcon from 'calcite-ui-icons-react/CaretDownIcon';
import Label from 'calcite-react/Label';

import {
  StyledDisplayCheckbox,
  StyledCheckboxInput,
  StyledCheckboxGroup,
  StyledCheckboxCheckIcon,
} from 'calcite-react/Checkbox/Checkbox-styled';

// JSON
import strings from 'strings/en.json';

// CSS
import AdaIcon from 'assets/wheelchair-move-medium-blue.svg';

const vaccineTypesPrimaryOrBooster = [
  'COVID Vaccine Moderna 12+',
  'COVID Vaccine Moderna 0-11',
  'COVID Vaccine Pfizer 12+',
  'COVID Vaccine Pfizer-5-11',
  'COVID Vaccine Pfizer 0-4',
  'COVID Vaccine Novavax',
];

const CovidFilterSubNav = ({ openFilter }) => {
  let showFilter = useRouteMatch({
    path: ['/locations/COVID/', '/'],
    exact: true,
  });

  const { attributeFilters, updateAttributeFilters } = useContext(AppContext);

  const [vaccineVisibility, setVaccineVisibility] = useState(false);
  const vaccineReferenceRef = useRef(null);
  const vaccinePopperRef = useRef(null);
  const firstVaccineRef = useRef(null);

  const { styles, attributes, update } = usePopper(
    vaccineReferenceRef.current,
    vaccinePopperRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );
  // const [draftVaccineTypeFilters, setDraftVaccineTypeFilters] = useState(
  //   attributeFilters.ServiceType || []
  // );
  const [draftVaccineTypeFilters, setDraftVaccineTypeFilters] = useState(() => {
    if (
      attributeFilters.ServiceType?.length !== 0 ||
      attributeFilters.ServiceType !== undefined
    ) {
      return attributeFilters.ServiceType;
    } else {
      return [
        'COVID Vaccine Moderna 12+',
        'COVID Vaccine Moderna 0-11',
        'COVID Vaccine Pfizer 12+',
        'COVID Vaccine Pfizer-5-11',
        'COVID Vaccine Pfizer 0-4',
        'COVID Vaccine Novavax',
      ];
    }
  });

  const toggleHandler = (type, value) => {
    let result = value ? null : 'Yes';
    if (type === 'walkIn') {
      updateAttributeFilters({
        ...attributeFilters,
        Intake_WalkIn: result,
      });
    } else if (type === 'accessible') {
      updateAttributeFilters({
        ...attributeFilters,
        ADA_Compliant: result,
      });
    }
  };

  const applyFilter = () => {
    updateAttributeFilters({
      ...attributeFilters,
      ServiceType: draftVaccineTypeFilters,
    });
    setVaccineVisibility(false);
  };

  const selectAllVaccines = () => {
    setDraftVaccineTypeFilters([...vaccineTypesPrimaryOrBooster]);
  };

  const updateDraftVaccineTypeFilters = (value, checked) => {
    if (checked) {
      setDraftVaccineTypeFilters([...draftVaccineTypeFilters, value]);
    } else {
      setDraftVaccineTypeFilters(
        draftVaccineTypeFilters.filter((activeField) => activeField !== value)
      );
    }
  };

  const hideVaccinePopper = (event) => {
    if (vaccineVisibility && event?.target?.tagName !== 'INPUT') {
      // Refocus dropdown button if the popuup was open
      // Don't change focus if they're clicking on an input
      vaccineReferenceRef.current.focus();
    }

    // Popup was closed without being applied
    setVaccineVisibility(false);

    // Revert draft values back to applied values
    setDraftVaccineTypeFilters(attributeFilters.ServiceType || []);
  };

  const handleKeyup = (e) => {
    if (e.key === 'Escape' && vaccineVisibility) {
      hideVaccinePopper();
    }
  };

  useEffect(() => {
    if (vaccineVisibility) {
      firstVaccineRef.current.focus();
    }

    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [vaccineVisibility, firstVaccineRef]);

  const showVaccinePopper = (event) => {
    update();
    setVaccineVisibility(true);
  };

  const containerStyle = {
    ...styles.popper,
    display: vaccineVisibility ? 'flex' : 'none',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
    padding: '10px',
  };

  const renderValue = (items) => {
    if (
      !items ||
      items.length === 0 ||
      items.length === vaccineTypesPrimaryOrBooster.length
    ) {
      return 'Any vaccine';
    } else {
      return `Vaccine (${items.length})`;
    }
  };

  const vaccineTypeAriaLabel = useMemo(() => {
    const serviceTypes = attributeFilters.ServiceType || [];
    if (serviceTypes.length === 0) {
      return '';
    } else if (serviceTypes.length === vaccineTypesPrimaryOrBooster) {
      return '  All Vaccine types selected.';
    }

    return ` ${serviceTypes.length} Vaccine type${
      serviceTypes.length > 1 ? 's' : ''
    } selected.`;
  }, [attributeFilters.ServiceType]);

  return (
    <StyledFilterSubNavContainer
      role="region"
      aria-label="Filter locations"
      showFilter={showFilter}
    >
      <FilterButton
        type="button"
        ref={vaccineReferenceRef}
        onClick={showVaccinePopper}
        aria-label={`Filter locations by vaccine type.${vaccineTypeAriaLabel}`}
      >
        {renderValue(attributeFilters.ServiceType || [])}
        <CaretDownIcon size={16} />
      </FilterButton>
      <div
        role="dialog"
        ref={vaccinePopperRef}
        style={containerStyle}
        {...attributes.popper}
      >
        <OutsideClickHandler onOutsideClick={hideVaccinePopper}>
          {vaccineTypesPrimaryOrBooster.map((vaccineType, i) => (
            <StyledCheckboxGroup key={vaccineType}>
              <StyledCheckboxInput
                autoFocus={i === 0}
                ref={i === 0 ? firstVaccineRef : null}
                checked={draftVaccineTypeFilters?.includes(vaccineType)}
                onChange={(e) => {
                  updateDraftVaccineTypeFilters(vaccineType, e.target.checked);
                }}
                type="checkbox"
                value={vaccineType}
                name={vaccineType}
              />
              <StyledDisplayCheckbox>
                <StyledCheckboxCheckIcon>
                  <path
                    d="M12.753 3l-7.319 7.497L3.252 8.31 2 9.373l3.434 3.434L14 4.24z"
                    fill="white"
                  />
                </StyledCheckboxCheckIcon>
              </StyledDisplayCheckbox>
              <StyledCalciteP>{strings[vaccineType]}</StyledCalciteP>
            </StyledCheckboxGroup>
          ))}
          <PopperActions>
            <Button inline onClick={() => selectAllVaccines()}>
              Select all
            </Button>
            <Button
              onClick={() => {
                applyFilter();

                // Refocus dropdown button
                vaccineReferenceRef.current.focus();
              }}
            >
              Done
            </Button>
          </PopperActions>
        </OutsideClickHandler>
      </div>
      <ToggleButton
        active={attributeFilters.Intake_WalkIn ? true : false}
        onClick={() => toggleHandler('walkIn', attributeFilters.Intake_WalkIn)}
        aria-label="Filter locations with walk up availability"
        aria-pressed={attributeFilters.Intake_WalkIn ? true : false}
      >
        Walk-ups
      </ToggleButton>
      <ToggleButton
        invertSvg
        active={attributeFilters.ADA_Compliant ? true : false}
        onClick={() => {
          toggleHandler('accessible', attributeFilters.ADA_Compliant);
        }}
        aria-label="Filter accessible locations"
        aria-pressed={attributeFilters.ADA_Compliant ? true : false}
      >
        <img
          className="tooltip-label"
          src={AdaIcon}
          alt="Wheelchair access icon"
          title="This site has a physically accessible pathway to the vaccine administration"
          style={{ width: 12, minWidth: 12, height: 14 }}
        />
      </ToggleButton>
      <InsuranceFilter />
    </StyledFilterSubNavContainer>
  );
};

CovidFilterSubNav.propTypes = {
  openFilter: PropTypes.func,
};

CovidFilterSubNav.defaultProps = {};

export default CovidFilterSubNav;
