import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { fields } from 'constants/map';
import { formatHref } from 'utils/helpers';
import {
  getFacilityById,
  goToFacilities,
  togglePopup,
  selectFacilityInGraphicsLayer,
  getDistanceFromFacility,
  getNoticeTable,
} from 'utils/arcgis';

import {
  _updateVaccineType,
  _updateAttributeFilters,
  _updateSearchTerm,
  _updateSpatialFilter,
  _updateFocalPoint,
  _updateAttributeSort,
  _updateSortBy,
  _updateSelectedFacility,
  _updateFacilities,
  _updateSortedFacilities,
  _updateMapView,
  _updateShowLoading,
  _updateBannerTexts,
  _updateFacilitiesAvail,
} from './AppController';

export const AppContext = createContext();

const AppContextProvider = (props) => {
  // App state
  const [dataReady, setDataReady] = useState(false);
  const [vaccineTypeFilter, setVaccineTypeFilter] = useState({
    VaccineType: window.location.href?.split('/locations/')[1]?.split('/')[0]
      ? window.location.href?.split('/locations/')[1]?.split('/')[0]
      : 'COVID',
  });
  const [attributeFilters, setAttributeFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [spatialFilter, setSpatialFilter] = useState(null);
  const [focalPoint, setFocalPoint] = useState(null);
  const [attributeSort, setAttributeSort] = useState([]);
  const [sortBy, setSortBy] = useState(fields.FACILITY_NAME);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [facilities, setFacilities] = useState(null);
  const [sortedFacilities, setSortedFacilities] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [preloadedFacilityId, setPreloadedFacilityId] = useState(null);
  const [showLoading, setShowLoading] = useState(0);
  const [bannerTexts, setBannerTexts] = useState([]);
  const [facilitiesAvail, setFacilitiesAvail] = useState(null);

  // Popup Actions
  const [popupDirectionsRequest, setPopupDirectionsRequest] = useState(null);

  // JSAPI state
  const [mapView, setMapView] = useState(null);
  const [locateWidget, setLocateWidget] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(null);

  const history = useHistory();

  //Updaters
  const updateBannerTexts = (args) => {
    _updateBannerTexts(args, setBannerTexts);
  };

  const updateShowLoading = (args) => {
    _updateShowLoading(args, setShowLoading);
  };

  const updateVaccineTypeFilter = (args) => {
    if (window.location.href?.includes('/locations/')) {
      if (window.location.href?.includes('?mView=map')) {
        history.push(
          '/locations/' +
            (args.VaccineType === 'Monkeypox' ? 'Mpox' : args.VaccineType) +
            '?mView=map'
        );
      } else {
        history.push(
          '/locations/' +
            (args.VaccineType === 'Monkeypox' ? 'Mpox' : args.VaccineType)
        );
      }
    }
    _updateVaccineType(args, setVaccineTypeFilter);
  };

  const updateAttributeFilters = (args) => {
    _updateAttributeFilters(args, setAttributeFilters);
  };

  const updateSearchTerm = (args) => {
    _updateSearchTerm(args, setSearchTerm);
  };

  const updateSpatialFilter = (args) => {
    _updateSpatialFilter(args, setSpatialFilter);
  };

  const updateFocalPoint = (args) => {
    _updateFocalPoint(args, setFocalPoint, setSearchTerm);
  };

  const updateAttributeSort = (args) => {
    _updateAttributeSort(args, setAttributeSort);
  };

  const updateSortBy = (args) => {
    _updateSortBy(args, setSortBy);
  };

  const updateSelectedFacility = (args) => {
    _updateSelectedFacility(args, setSelectedFacility, vaccineTypeFilter, {
      history,
    });
  };

  const updateFacilities = (args) => {
    _updateFacilities(args, setFacilities);
  };

  const updateFacilitiesAvail = (args) => {
    _updateFacilitiesAvail(args, setFacilitiesAvail);
  };

  const updateSortedFacilities = () => {
    let newArgs = {
      mapView: mapView,
      vaccineTypeFilter: vaccineTypeFilter,
      attributeFilters: attributeFilters,
    };
    if (!!mapView)
      _updateSortedFacilities(
        newArgs,
        setSortedFacilities,
        showLoading,
        setShowLoading
      );
  };

  const updateMapView = async (mapView) => {
    // Add mapView to context
    await _updateMapView(mapView, setMapView);
  };

  // Select preloaded facility if it exists
  useEffect(() => {
    const f = async () => {
      if (mapView && preloadedFacilityId) {
        const facility = await getFacilityById({
          mapView,
          facilityId: preloadedFacilityId,
        });
        // Add proximity for preselected facility since this is called on render of the facility details view
        if (focalPoint) {
          facility.proximity = getDistanceFromFacility(focalPoint, facility);
        }
        updateSelectedFacility(facility);
      }
    };
    f();
  }, [mapView, preloadedFacilityId]);

  // Update the selected facility on the map
  useEffect(() => {
    const f = async () => {
      if (mapView) {
        selectFacilityInGraphicsLayer({
          mapView,
          setMapLoaded,
          feature: selectedFacility,
          updateSelectedFacility,
          vaccineTypeFilter,
          focalPoint,
          setPopupDirectionsRequest,
        });
      }
    };
    f();
  }, [mapView, vaccineTypeFilter, attributeFilters]);

  useEffect(() => {
    const f = async () => {
      if (mapView) {
        // Zoom the map to the selected facility and show the popup
        await goToFacilities({
          mapView,
          features: selectedFacility ? [selectedFacility] : null,
        });
        togglePopup({ mapView, feature: selectedFacility });
      }
    };
    f();
  }, [mapView, selectedFacility]);

  useEffect(() => {
    const f = () => {
      if (
        !window.location.href.includes('/locations') &&
        searchTerm !== '' &&
        searchTerm
      )
        history.push(
          '/locations/' +
            (vaccineTypeFilter.VaccineType === 'Monkeypox'
              ? 'Mpox'
              : vaccineTypeFilter.VaccineType)
        );
    };
    f();
  }, [searchTerm]);

  // Update facilities list when necessary
  useEffect(() => {
    if (mapView) {
      updateFacilities({
        mapView,
        vaccineTypeFilter,
        attributeFilters,
        spatialFilter,
        focalPoint,
      });
      // Update total number of avaialable facilities for monkeypox (NOT CURRENTLY USED)
      // if (vaccineTypeFilter.VaccineType === 'Monkeypox') {
      //   updateFacilitiesAvail({
      //     mapView,
      //     vaccineTypeFilter,
      //   });
      //}
    }
  }, [
    mapView,
    vaccineTypeFilter,
    attributeFilters,
    spatialFilter,
    focalPoint,
    history,
  ]);

  //update banner text
  useEffect(() => {
    const f = async () => {
      updateBannerTexts(await getNoticeTable());
    };
    f();
  }, [mapView]);

  useEffect(() => {
    if (popupDirectionsRequest) {
      console.log('directions requested', popupDirectionsRequest);
      console.log('focalPoint', focalPoint);
      const href = formatHref({ facility: popupDirectionsRequest, focalPoint });
      window.open(href);
      setPopupDirectionsRequest(null);
    }
  }, [popupDirectionsRequest]);

  return (
    <AppContext.Provider
      value={{
        dataReady,
        setDataReady,
        vaccineTypeFilter,
        updateVaccineTypeFilter,
        attributeFilters,
        updateAttributeFilters,
        searchTerm,
        updateSearchTerm,
        spatialFilter,
        updateSpatialFilter,
        focalPoint,
        updateFocalPoint,
        attributeSort,
        updateAttributeSort,
        sortBy,
        updateSortBy,
        selectedFacility,
        updateSelectedFacility,
        facilities,
        updateFacilities,
        facilitiesAvail,
        updateFacilitiesAvail,
        sortedFacilities,
        updateSortedFacilities,
        filterVisible,
        setFilterVisible,
        preloadedFacilityId,
        setPreloadedFacilityId,
        showLoading,
        updateShowLoading,
        bannerTexts,
        updateBannerTexts,

        // Popup Actions
        popupDirectionsRequest,
        setPopupDirectionsRequest,

        // JSAPI state
        mapView,
        updateMapView,
        locateWidget,
        setLocateWidget,
        mapLoaded,
        setMapLoaded,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

// *
// * This is the shape of our AppContext (implemented as state variables above)
// *

// const shape = {
//   attributeFilters: ['filters'],
//   spatialFilter: 'extent' || null,
//   focalPoint: {'Point Feature', 'label'} || null,
//   attributeSort: ['field ASC'],
//   sortBy: 'attribute' || 'proximity',
//   selectedFacility: Facility Feature,
//   facilities: ['features']
// };
