import styled, { css } from 'styled-components';
import Button, { ButtonGroup } from 'calcite-react/Button';
import { isFluSeason, isMonkPoxSeason } from 'constants/map';

export const ToggleContainer = styled.div`
  max-width: 350px;
  margin: 6px 0 6px 0;
  background: ${(props) => props.theme.palette.colors.white};
  border-radius: 20px;
  border: 1px solid #007ac2;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
  transition: all 0.15s ease-in-out;
  border-radius: 20px;
  pointer-events: none;
  border: none;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: #ffffff;
      background: #004874;
    }
  }
  > #active-button {
    pointer-events: auto;
  }
`;

export const StyledButton = styled(Button)`
  width: 30vw !important;
  padding: 0.2rem 0 0.2rem 0;
  border-right: none;
  border-bottom: none;
  border-top: none;
  @media (max-width: 650px) {
      :hover {
        color: #007ac2;
        background: ${(props) => props.theme.palette.colors.white};
      }
    }
  }
  ${(props) =>
    !props.active &&
    css`
      color: #ffffff !important;
      z-index: 1;
    `};

  ${(props) =>
    isFluSeason &&
    isMonkPoxSeason &&
    props.tabindex === 0 &&
    css`
      width: 40vw !important;
    `};
  ${(props) =>
    isFluSeason &&
    isMonkPoxSeason &&
    props.tabindex === 1 &&
    css`
      width: 15vw !important;
    `};
  ${(props) =>
    isFluSeason &&
    isMonkPoxSeason &&
    props.tabindex === 2 &&
    css`
      width: 40vw !important;
    `};

    ${(props) =>
      !isFluSeason &&
      isMonkPoxSeason &&
      props.tabindex === 1 &&
      css`
        width: 40vw !important;
      `};
    }
`;
