// Framework and third-party non-ui
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledTranslationContainer } from './TranslationSelect-styled';

// App components

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const TranslationSelect = () => {
  const translateContainer = useRef(null);

  useEffect(() => {
    if (translateContainer) {
      translateContainer.current.appendChild(
        document.getElementById('google_translate_element')
      );
    }
  }, [translateContainer]);

  return (
    <StyledTranslationContainer
      aria-label="Google Translate Widget"
      ref={translateContainer}
      role="navigation"
    />
  );
};

TranslationSelect.propTypes = {
  noPosition: PropTypes.bool,
};

TranslationSelect.defaultProps = {};

export default TranslationSelect;
