// Framework and third-party non-ui
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  FilterButton,
  PopperActions,
} from '../CovidFilterSubNav/CovidFilterSubNav-styled';

// Third-party components (buttons, icons, etc.)
import { usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'calcite-react/Button';
import CaretDownIcon from 'calcite-ui-icons-react/CaretDownIcon';

import {
  StyledDisplayCheckbox,
  StyledCheckboxInput,
  StyledCheckboxGroup,
  StyledCheckboxCheckIcon,
} from 'calcite-react/Checkbox/Checkbox-styled';

import { StyledCalciteP } from './InsuranceFilter-styled';

// JSON
import strings from 'strings/en.json';

const insuranceTypes = ['private', 'lownocost', 'medicaid'];

const InsuranceFilter = () => {
  const { attributeFilters, updateAttributeFilters } = useContext(AppContext);

  const [insuranceVisibility, setInsuranceVisibility] = useState(false);
  const insuranceReferenceRef = useRef(null);
  const insurancePopperRef = useRef(null);
  const firstInsuranceRef = useRef(null);

  const { styles, attributes, update } = usePopper(
    insuranceReferenceRef.current,
    insurancePopperRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  const [draftPrviateFilter, setDraftPrivateFilter] = useState(
    attributeFilters.Payment_Private || ''
  );
  const [draftLowNoCostFilter, setDraftLowNoCostFilter] = useState(
    attributeFilters.Payment_LowNoCost || ''
  );
  const [draftMedicaidFilter, setDraftMedicaidFilter] = useState(
    attributeFilters.Payment_Medicaid || ''
  );

  useEffect(() => {
    applyFilter();
  }, []);

  const applyFilter = () => {
    updateAttributeFilters({
      ...attributeFilters,
      Payment_Medicaid: draftMedicaidFilter,
      Payment_LowNoCost: draftLowNoCostFilter,
      Payment_Private: draftPrviateFilter,
    });
    setInsuranceVisibility(false);
  };

  const selectAllInsurances = () => {
    setDraftMedicaidFilter('Yes');
    setDraftLowNoCostFilter('Yes');
    setDraftPrivateFilter('Yes');
  };

  const applyCheck = (value) => {
    switch (value) {
      case 'medicaid':
        return draftMedicaidFilter === 'Yes';
      case 'lownocost':
        return draftLowNoCostFilter === 'Yes';
      case 'private':
        return draftPrviateFilter === 'Yes';
    }
  };

  const updateInsuranceFilter = (value, checked) => {
    if (checked) {
      switch (value) {
        case 'medicaid':
          setDraftMedicaidFilter('Yes');
          break;
        case 'lownocost':
          setDraftLowNoCostFilter('Yes');
          break;
        case 'private':
          setDraftPrivateFilter('Yes');
          break;
      }
    } else {
      switch (value) {
        case 'medicaid':
          setDraftMedicaidFilter('');
          break;
        case 'lownocost':
          setDraftLowNoCostFilter('');
          break;
        case 'private':
          setDraftPrivateFilter('');
          break;
      }
    }
  };

  const hideInsurancePopper = (event) => {
    if (insuranceVisibility && event.target.tagName !== 'INPUT') {
      // Refocus dropdown button if the popuup was open
      // Don't change focus if they're clicking on an input
      insuranceReferenceRef.current.focus();
    }

    // Popup was closed without being applied
    setInsuranceVisibility(false);

    // Revert draft values back to applied values
    setDraftMedicaidFilter(attributeFilters.Payment_Medicaid || '');
    setDraftLowNoCostFilter(attributeFilters.Payment_LowNoCost || '');
    setDraftPrivateFilter(attributeFilters.Payment_Private || '');
  };

  const handleKeyup = (e) => {
    if (e.key === 'Escape' && insuranceVisibility) {
      hideInsurancePopper();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [insuranceVisibility, firstInsuranceRef]);

  const showInsurancePopper = (event) => {
    update();
    setInsuranceVisibility(true);
  };

  const containerStyle = {
    ...styles.popper,
    display: insuranceVisibility ? 'flex' : 'none',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
    padding: '10px',
  };

  const renderValue = (items) => {
    let insurances = [];
    if (!!items.Payment_Medicaid) insurances.push('1');
    if (!!items.Payment_LowNoCost) insurances.push('1');
    if (!!items.Payment_Private) insurances.push('1');
    if (
      !insurances ||
      insurances.length === 0 ||
      insurances.length === insuranceTypes.length
    ) {
      return 'Any insurance';
    } else {
      return `Insurance (${insurances.length})`;
    }
  };

  const insuranceTypeAriaLabel = useMemo(() => {
    const serviceTypes = attributeFilters.InsuranceType || [];
    if (serviceTypes.length === 0) {
      return '';
    } else if (serviceTypes.length === insuranceTypes.length) {
      return '  All insurance types selected.';
    }

    return ` ${serviceTypes.length} insurance type${
      serviceTypes.length > 1 ? 's' : ''
    } selected.`;
  }, [attributeFilters.InsuranceType]);

  return (
    <>
      <FilterButton
        type="button"
        ref={insuranceReferenceRef}
        onClick={showInsurancePopper}
        aria-label={`Filter locations by insurance type.${insuranceTypeAriaLabel}`}
      >
        {renderValue(attributeFilters || [])}
        <CaretDownIcon size={16} />
      </FilterButton>
      <div
        role="dialog"
        ref={insurancePopperRef}
        style={containerStyle}
        {...attributes.popper}
      >
        <OutsideClickHandler onOutsideClick={hideInsurancePopper}>
          {insuranceTypes.map((insuranceType, i) => (
            <StyledCheckboxGroup key={insuranceType}>
              <StyledCheckboxInput
                autoFocus={i === 0}
                ref={i === 0 ? firstInsuranceRef : null}
                checked={applyCheck(insuranceType)}
                onChange={(e) => {
                  updateInsuranceFilter(insuranceType, e.target.checked);
                }}
                type="checkbox"
                value={insuranceType}
                name={insuranceType}
              />
              <StyledDisplayCheckbox>
                <StyledCheckboxCheckIcon>
                  <path
                    d="M12.753 3l-7.319 7.497L3.252 8.31 2 9.373l3.434 3.434L14 4.24z"
                    fill="white"
                  />
                </StyledCheckboxCheckIcon>
              </StyledDisplayCheckbox>
              <StyledCalciteP>{strings[insuranceType]}</StyledCalciteP>
            </StyledCheckboxGroup>
          ))}
          <PopperActions>
            <Button inline onClick={() => selectAllInsurances()}>
              Select all
            </Button>
            <Button
              onClick={() => {
                applyFilter();

                // Refocus dropdown button
                insuranceReferenceRef.current.focus();
              }}
            >
              Done
            </Button>
          </PopperActions>
        </OutsideClickHandler>
      </div>
    </>
  );
};

InsuranceFilter.propTypes = {
  openFilter: PropTypes.func,
};

InsuranceFilter.defaultProps = {};

export default InsuranceFilter;
