// Framework and third-party non-ui
import React, { useState, useEffect, useContext } from 'react';

// Redux operations and local helpers/utils/modules
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledMobileMapSectionContainer,
  StyledMobileMapContainer,
  StyledMapOverlay,
  StyledCalciteH5,
} from './MobileMapSection-styled';

// App components
import MapListToggle from 'components/MapListToggle';

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const MobileMapSection = () => {
  const { mapView, mapLoaded } = useContext(AppContext);
  const [mapScreenshot, setMapScreenshot] = useState(null);

  useEffect(() => {
    if (mapView && mapLoaded) {
      const screenshotTimeout = setTimeout(() => {
        mapView
          .takeScreenshot({ format: 'png', width: 400, height: 300 })
          .then(function (screenshot) {
            setMapScreenshot(screenshot);
          });
        clearTimeout(screenshotTimeout);
      }, 2000);

      return () => {
        clearTimeout(screenshotTimeout);
      };
    }
  }, [mapView, mapLoaded]);

  return (
    <StyledMobileMapSectionContainer>
      {<StyledCalciteH5>Location on map</StyledCalciteH5>}
      <StyledMobileMapContainer>
        {mapScreenshot && (
          <img src={mapScreenshot.dataUrl} alt="map of this facility" />
        )}
        <StyledMapOverlay>
          <MapListToggle />
        </StyledMapOverlay>
      </StyledMobileMapContainer>
    </StyledMobileMapSectionContainer>
  );
};

MobileMapSection.propTypes = {};

MobileMapSection.defaultProps = {};

export default MobileMapSection;
