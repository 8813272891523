// Framework and third-party non-ui
import { createGlobalStyle } from 'styled-components';

// Calcite theme and Esri colors
import { CalciteTheme as theme } from 'calcite-react/CalciteThemeProvider';

import { StyledSideNav } from 'calcite-react/SideNav/SideNav-styled';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }

  #root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    @keyframes fadeInAndDown {
      0% {
        opacity: 0;
        transform: translateY(-2px);
      }
    }
  }

  ${StyledSideNav} {
    border-radius: 0;
  }

  select.goog-te-combo {
    font-size: 16px;
  }

  .esri-popup__main-container {
    width: inherit !important;
    max-width: 34rem;
  }

  .esri-popup__content {
    margin-inline: 0;
  }

  .esri-feature__content-element {
    padding: 0 0.755rem;
  }

  .esri-popup__inline-actions-container {
    display: inline-block;
    line-height: 2rem;
    
    [title="Schedule appointment"],
    [title="Call for appointment"] {
      .esri-popup__action-text {
        display: flex;
      }
    }

    [title="Zoom to"] {
      display: none;
    }

    .get-directions, .share-link {
      margin: 0 !important;
    }

    .get-directions + span,
    .share-link + span {
      display: none;
    }

    & > .esri-popup__button {
      height: 30px;
      margin: 0 3px;

      &:first-child {
        margin-inline-start: 7px;
      }
    }

    .esri-popup__actions-menu-button {
      display: none;
    }
  }

  .esri-popup__inline-actions-container > .esri-popup__action {
    background: ${(props) => props.theme.palette.blue};
    color: ${(props) => props.theme.palette.white};
    border-radius: 20px;
    padding: 0.31rem 1rem;
    display: inline-flex;
    /* flex-direction: row-reverse; */
    align-items: center;
    max-width: none !important;

    &:hover {
      background: ${(props) => props.theme.palette.darkBlue};
      color: ${(props) => props.theme.palette.white};
    }

    .esri-popup__action-text {
      line-height: 1.25rem;
      margin: 0 .25rem;
      font-size: 0.9375rem;
    }
  }

  .esri-popup__header {
    display: none !important;
  }
  .esri-attribution {
    background: #fff !important;
  }

  .goog-te-gadget {
    background: #ffffff !important;
  }

  .skip-link {
    left: 50%;
    position: absolute;
    transform: translateY(-100%);
  }
  .skip-link:focus {
    transform: translateY(0%);
  }

`;

export default GlobalStyle;
