import styled, { css } from 'styled-components';

import Button from 'calcite-react/Button';

export const ToggleButton = styled(Button).attrs((attrs) => {
  return {
    clear: !attrs.active,
    ...attrs,
  };
})`
  height: 1.8rem;
  font-size: 0.9rem;
  color: ${(props) => props.theme.palette.colors.white};
  background-color: ${(props) => props.theme.palette.colors.primary};
  border-color: ${(props) => props.theme.palette.colors.white};
  border-radius: 0;
  padding-inline-start: ${(props) => props.theme.global.padding};
  padding-inline-end: ${(props) => props.theme.global.padding};

  :hover {
    border-color: ${(props) => props.theme.palette.colors.white};
  }

  ${(props) =>
    props.invertSvg &&
    css`
      img {
        filter: brightness(0) invert(1);
      }
    `};

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.palette.colors.button};
      background-color: ${(props) => props.theme.palette.colors.white};

      ${(props) =>
        props.invertSvg &&
        css`
          img {
            filter: none;
          }
        `};

      :hover {
        background: ${(props) => props.theme.palette.colors.white};
        color: ${(props) => props.theme.palette.colors.button};
        opacity: 0.9;
      }
    `};

  ${(props) =>
    props.outline &&
    css`
      border-color: ${(props) => props.theme.palette.colors.buttonSelected};
      color: ${(props) => props.theme.palette.colors.offBlack};
    `};
`;
